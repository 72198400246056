import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthClientScreen from 'src/screens/auth/auth.component';
import BrokerAuthScreen from 'src/screens/auth/components/broker.auth.component';
import BrokerSignupScreen from 'src/screens/auth/components/brokerSignup/brokerSignup.component';
import OwnerAuthScreen from 'src/screens/auth/components/owner.auth.component';
import OwnerSignupScreen from 'src/screens/auth/components/ownerSignup/ownerSignup.component';
import NotFound from 'src/screens/error/404/404.component';
import { IProps } from './guest.type';

export default class GuestRoutes extends React.PureComponent<IProps> {
  private GuestRoute = ({ component: Component, ...rest }: any) => (
    <Route
      {...rest}
      render={(propsComponent) =>
        !this.props.user ? (
          <Component {...propsComponent} />
        ) : (
          <Redirect to={{ pathname: '/painel' }} />
        )
      }
    />
  );

  public render() {
    const { user } = this.props;
    const GuestRoute = this.GuestRoute;

    return (
      <Switch>
        <GuestRoute path="/" exact component={AuthClientScreen} />
        <GuestRoute path="/proprietario/" exact component={OwnerAuthScreen} />
        <GuestRoute
          path="/proprietario/cadastrar"
          exact
          component={OwnerSignupScreen}
        />
        <GuestRoute path="/corretor/" exact component={BrokerAuthScreen} />
        <GuestRoute
          path="/corretor/cadastrar"
          exact
          component={BrokerSignupScreen}
        />

        {!user && <GuestRoute component={NotFound} />}
      </Switch>
    );
  }
}
