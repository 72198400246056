import { Dispatch } from 'react';
import {
  ICreateClientInput,
  ISendPDFToClientInput,
} from 'src/interfaces/client';
import { clientServices } from 'src/services/clients';
import { forms } from 'src/utils';
import { store } from '..';
import {
  ClientActionTypes,
  CREATE_CLIENT,
  FETCH_CLIENT,
  FETCH_CLIENTS,
  SEND_PDFS,
} from './types';

export const fetchClient =
  (id: string) => (dispatch: Dispatch<ClientActionTypes>) => {
    return clientServices
      .client(id)
      .then((res) => {
        dispatch({
          type: FETCH_CLIENT,
          payload: res,
        });
        return res;
      })
      .catch(() => {
        throw Error;
      });
  };

export const fetchClients = () => (dispatch: Dispatch<ClientActionTypes>) => {
  return clientServices
    .clients()
    .then((res) => {
      dispatch({
        type: FETCH_CLIENTS,
        payload: res,
      });
      return res;
    })
    .catch(() => {
      throw Error;
    });
};

export const createClient =
  (input: ICreateClientInput) => (dispatch: Dispatch<ClientActionTypes>) => {
    input.brokerId = store.getState().broker.broker?.broker.id!;
    return clientServices
      .createClient(input)
      .then((res) => {
        dispatch({
          type: CREATE_CLIENT,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        forms.errors(err);
      });
  };

export const sendPDFs =
  (input: ISendPDFToClientInput) => (dispatch: Dispatch<ClientActionTypes>) => {
    return clientServices
      .sendPDFsToClients(input)
      .then((res) => {
        dispatch({
          type: SEND_PDFS,
          payload: res,
        });
        return res;
      })
      .catch((err) => console.log(err));
  };
