import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IImage } from 'src/interfaces/misc';
import { Wrapper } from './carousel.styles';

interface IProps {
  images?: IImage[];
}

const ImagesGallery: React.FC<IProps> = (props) => {
  const { images } = props;
  return (
    <Wrapper>
      <Carousel
        showArrows={true}
        showStatus={false}
        width="100%"
        useKeyboardArrows
        onClickItem={(index, item) => console.log(index)}
        showThumbs={false}
      >
        {images?.map((image) => (
          <div key={image.path}>
            <img src={image.path} alt="Imagens" />
          </div>
        ))}
      </Carousel>
    </Wrapper>
  );
};

export default ImagesGallery;
