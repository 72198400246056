import { IAppointment } from "src/interfaces/appointment";


export const FETCH_APPOINTMENTS = "FETCH_APPOINTMENTS";
export const CREATE_APPOINTMENT =  "CREATE_APPOINTMENT";
export const CLEAR_APPOINTMENTS = 'CLEAR_APPOINTMENTS';


export interface IReducerAppointmentState {
  appointment?: IAppointment;
  appointments?: IAppointment[];
}


export interface IFetchAppointments {
  type: typeof FETCH_APPOINTMENTS;
  payload: IAppointment[];
}

export interface ICreateAppointment {
  type: typeof CREATE_APPOINTMENT;
  payload: IAppointment;
}

export interface IClearAppointment {
  type: typeof CLEAR_APPOINTMENTS;
}


export type AppointmentActionTypes = IFetchAppointments | ICreateAppointment | IClearAppointment;