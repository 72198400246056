import styled from 'styled-components';

export const Container = styled.div`
  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    font-size: 1rem;
    position: relative;
    height: 100px;
    div {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .toggle {
      background-color: transparent;
      outline: none;
      cursor: pointer;
      border: none;
    }
    .text-logo {
      position: absolute;
      width: 100%;
      height: 100%;
      /* path {
        fill: #000;
      } */
    }
    .spinning-logo {
      width: 105px;
      left: 40px;
      top: 0px;
      height: 105px;
      position: absolute;
      transform-origin: 54px 53px;
      -webkit-animation: Spin linear 5s infinite;
      animation: Spin linear 5s infinite;
    }

    .collapsedSpinningLogo {
      width: 40px;
      height: 40px;
      left: -15px;
      top: 10px;
      height: 80px;
      position: absolute;
      /* transform-origin: 36px 41px; */

      animation: Spin linear 5s infinite;
    }

    @keyframes Spin {
      100% {
        transform: rotate(360deg);
      }
    }

    margin: 16px;
    font-weight: 400;
    font-family: Orbitron;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background-color: var(--white);
    transition: none;
    svg {
      color: var(--purple-dark);
    }
    a {
      color: var(--purple-dark);
    }
    span {
      color: var(--purple-dark);
    }
    &::after {
      content: '';
      position: absolute;
      height: 30px;
      width: 30px;
      top: 40px;
      background: radial-gradient(
        circle at bottom left,
        transparent 30px,
        var(--white) 31px
      );
      right: 0rem;
      transform: none;
      opacity: 1;
      box-shadow: 3px 0px 0px 0px var(--white);
      transition: none;
    }
    &::before {
      cursor: default;
      content: '';
      position: absolute;
      height: 30px;
      width: 30px;
      right: 0;
      bottom: 40px;
      background-color: transparent;
      background: radial-gradient(
        circle at top left,
        transparent 30px,
        var(--white) 31px
      );
      box-shadow: 3px 0px 0px 0px var(--white);
    }
  }
  .separator {
    height: 1px;
    background-color: var(--white);

    margin: 24px 20px;
  }
  ul.ant-menu-inline-collapsed {
    li {
      padding-left: 32px !important;
      font-size: 1rem;
    }
  }
  ul {
    li {
      transition: none;

      overflow: unset !important;
      display: flex;
      align-items: center;
      margin-bottom: 2rem !important;
      font-size: 16px;
      padding: 8px 16px !important;
      font-family: Orbitron, sans-serif;
      font-weight: 400;
      line-height: 1rem;
      letter-spacing: 0.5px;
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
`;
