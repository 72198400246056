import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notification, Table } from 'antd';

import { IReduxState } from 'src/store';
import { IClient } from 'src/interfaces/client';
import { fetchClients } from 'src/store/clients/actions';
import TableActions from 'src/components/tableActions/tableActions.component';
import { Card } from 'src/components';
import { table } from 'src/utils';
import PropertyPDF from 'src/components/propertyPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';

interface IProps {
  clients?: IClient[];
  fetchClients: () => Promise<IClient[]>;
}

const Clients: React.FC<IProps> = (props) => {
  const { clients } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    props
      .fetchClients()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        return notification.error({
          message:
            'Ocorreu um erro ao buscar as informações dos seus clientes. Por favor, tente novamente mais tarde.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clients]);

  const dataSource = clients?.map((client) => ({
    key: client.id,
    name: client.fullName,
    document: client.document,
    phone: client.phone,
    address: `${client.address.state}-${client.address.city}, ${client.address.neighborhood}, ${client.address.street} - ${client.address.number}`,
  }));

  const columns = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'CPF | CNPJ',
      key: 'document',
      dataIndex: 'document',
    },
    {
      title: 'Telefone',
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: 'Endereço',
      key: 'address',
      dataIndex: 'address',
    },
    {
      title: 'Ações',
      key: 'actions',
      dataIndex: 'actions',
      render: (text: string, record: any) => (
        <>
          <TableActions
            view={{ link: `/painel/clientes/${record.key}` }}
            loading={loading}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Card title="Clientes" subtitle="Seus clientes cadastrados no sistema.">
        <Table
          scroll={{ x: 500 }}
          dataSource={dataSource}
          {...table}
          columns={columns}
          loading={loading}
        />
      </Card>
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  clients: state.client.clients,
});

export default connect(mapStateToProps, { fetchClients })(Clients);
