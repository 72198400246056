import gql from 'graphql-tag';
import {
  ICreateClientInput,
  ISendPDFToClientInput,
} from 'src/interfaces/client';
import client from './client.auth';

export const clientServices = {
  client: (id: string) =>
    client
      .query({
        query: gql`
          query client($id: ID!) {
            me {
              id
              broker {
                id
                client(id: $id) {
                  id
                  fullName
                  maritalStatus
                  phone
                  email
                  document
                  cnpj
                  address {
                    zipcode
                    state
                    city
                    complement
                    complex
                    neighborhood
                    street
                    number
                  }
                  broker {
                    id
                    cnpj
                    creci
                    cpf
                  }
                }
              }
            }
          }
        `,
        variables: { id },
      })
      .then((res) => res.data.me.broker.client),

  clients: () =>
    client
      .query({
        query: gql`
          query clients {
            me {
              id
              broker {
                id
                clients {
                  id
                  fullName
                  maritalStatus
                  phone
                  document
                  cnpj
                  address {
                    zipcode
                    state
                    city
                    complement
                    complex
                    neighborhood
                    street
                    number
                  }
                  broker {
                    id
                    creci
                    cnpj
                    cpf
                  }
                }
              }
            }
          }
        `,
      })
      .then((res) => res.data.me.broker.clients),

  createClient: (input: ICreateClientInput) =>
    client
      .mutate({
        mutation: gql`
          mutation createClient($input: CreateClientInput!) {
            createClient(input: $input) {
              id
              maritalStatus
              phone
              fullName
              document
              cnpj
              address {
                zipcode
                state
                city
                complement
                complex
                neighborhood
                street
                number
              }
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.createClient),

  sendPDFsToClients: (input: ISendPDFToClientInput) =>
    client
      .mutate({
        mutation: gql`
          mutation sendPDFToClient($input: SendPDFToClientInput!) {
            sendPDFToClient(input: $input) {
              id
              fullName
              email
              cnpj
              document
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.sendPDFToClient),
};
