import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

export const Text = styled.p`
  font-size: 13px;
  color: var(--text-color);
`;
