import { Row, Col, Divider, Descriptions } from 'antd';
import React from 'react';
import { MdBrokenImage } from 'react-icons/md';

import styles, {
  ImageMask,
  ImageNotFound,
  NotFound,
} from './../styles/showProperty.styles';

interface IProps {
  scripture?: string;
  registration?: string;
  subscription?: string;
  car?: string;
  ccir?: string;
  itr?: string;
}

const DocumentsData: React.FC<IProps> = (props) => {
  const { scripture, registration, subscription, car, ccir, itr } = props;
  return (
    <>
      <Descriptions title="Documentos do Imóvel" />
      <Row justify="center" align="middle">
        <Col className="center-col" md={8} xs={24} sm={12}>
          {scripture?.includes('.pdf') ? (
            <>
              <h1>Escritura</h1>
              <a target="_blank" rel="noopener noreferrer" href={scripture}>
                Visualizar Escritura
              </a>
            </>
          ) : (
            <>
              {!scripture ? (
                <>
                  <NotFound>
                    <h1>Escritura</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={scripture} alt="Escritura" />
                </ImageMask>
              )}
            </>
          )}
        </Col>

        <Col className="center-col" md={8} xs={24} sm={12}>
          {registration?.includes('.pdf') ? (
            <>
              <h1>Matrícula (RGI)</h1>
              <a
                style={styles.a}
                target="_blank"
                rel="noopener noreferrer"
                href={registration}
              >
                Visualizar Escritura
              </a>
            </>
          ) : (
            <>
              {!registration ? (
                <>
                  <NotFound>
                    <h1>Matrícula (RGI)</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={registration} alt="Matrícula" />
                </ImageMask>
              )}
            </>
          )}
        </Col>

        <Col className="center-col" md={8} xs={24} sm={12}>
          {subscription?.includes('.pdf') ? (
            <>
              <h1>Inscrição Municipal</h1>
              <a target="_blank" rel="noopener noreferrer" href={subscription}>
                Visualizar Inscrição
              </a>
            </>
          ) : (
            <>
              {!subscription ? (
                <>
                  <NotFound>
                    <h1>Inscrição Municipal</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={subscription} alt="Inscrição Municipal" />
                </ImageMask>
              )}
            </>
          )}
        </Col>
        <Col className="center-col" md={8} xs={24} sm={12}>
          {car?.includes('.pdf') ? (
            <>
              <h1>CAR</h1>
              <a target="_blank" rel="noopener noreferrer" href={car}>
                visualizar CAR
              </a>
            </>
          ) : (
            <>
              {!car ? (
                <>
                  <NotFound>
                    <h1>CAR</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={car} alt="CAR" />
                </ImageMask>
              )}
            </>
          )}
        </Col>
        <Col className="center-col" md={8} xs={24} sm={12}>
          {itr?.includes('.pdf') ? (
            <>
              <h1>ITR</h1>
              <a target="_blank" rel="noopener noreferrer" href={itr}>
                Visualizar ITR
              </a>
            </>
          ) : (
            <>
              {!itr ? (
                <>
                  <NotFound>
                    <h1>ITR</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={itr} alt="ITR" />
                </ImageMask>
              )}
            </>
          )}
        </Col>
        <Col className="center-col" md={8} xs={24} sm={12}>
          {ccir?.includes('.pdf') ? (
            <>
              <h1>CCIR</h1>
              <a target="_blank" rel="noopener noreferrer" href={ccir}>
                Visualizar CCIR
              </a>
            </>
          ) : (
            <>
              {!ccir ? (
                <>
                  <NotFound>
                    <h1>CCIR</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={ccir} alt="CCIR" />
                </ImageMask>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DocumentsData;
