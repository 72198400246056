import React, { useState } from 'react';
import { Divider, Drawer, Menu } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import {
  FaBook,
  FaClock,
  FaHome,
  FaPlusCircle,
  FaSearch,
  FaSignOutAlt,
  FaUser,
  FaUserPlus,
  FaUsers,
} from 'react-icons/fa';
import { connect } from 'react-redux';

import { LOGOUT_USER } from 'src/store/auth/types';
import { CLEAR_OWNER } from 'src/store/owner/types';
import { CLEAR_BROKER } from 'src/store/broker/types';
import { IReduxState, store } from 'src/store';

import { LogoTitle, DrawerBody } from './drawer.styles';
import { IUser } from 'src/interfaces/user';
import Logo from 'src/assets/images/logo_PNG.png';
import { CLEAR_APPOINTMENTS } from 'src/store/appointments/types';

interface IProps {
  visible: boolean;
  onClose: () => void;
  auth?: IUser;
}

const DrawerComponent: React.FC<IProps> = (props) => {
  const { onClose, visible, auth } = props;
  const [redirect, setRedirect] = useState<string>('');

  const { Item } = Menu;

  const handleOwnerLogout = async () => {
    store.dispatch({
      type: CLEAR_OWNER,
    });

    await store.dispatch({
      type: LOGOUT_USER,
    });
  };

  const handleBrokerLogout = async () => {
    store.dispatch({
      type: CLEAR_BROKER,
    });

    store.dispatch({
      type: CLEAR_APPOINTMENTS,
    });

    await store.dispatch({
      type: LOGOUT_USER,
    });

    setRedirect('/');
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      {!!auth ? (
        <Drawer
          width={200}
          closable={false}
          placement="left"
          visible={visible}
          onClose={onClose}
        >
          <LogoTitle>
            <img src={Logo} alt="Logo" />
          </LogoTitle>
          <Divider style={{ width: '100%' }} />
          <DrawerBody>
            {auth?.role === 'owner' ? (
              <Menu mode="inline">
                <Item key="0" icon={<FaHome />}>
                  <Link to="/painel">Início</Link>
                </Item>
                <Item key="1" icon={<FaBook />}>
                  <Link to="/painel/imoveis">Meus Imóveis</Link>
                </Item>
                <Item key="2" icon={<FaClock />}>
                  <Link to="/painel/imoveis/pendentes">Pendentes</Link>
                </Item>
                <Item key="3" icon={<FaPlusCircle />}>
                  <Link to="/painel/imoveis/cadastrar">Cadastrar imóvel</Link>
                </Item>
                <Item key="4" icon={<FaUser />}>
                  Configurações
                </Item>

                <Item
                  key="10203"
                  icon={<FaSignOutAlt />}
                  onClick={handleOwnerLogout}
                >
                  Sair
                </Item>
              </Menu>
            ) : (
              <Menu mode="inline">
                <Item key="0" icon={<FaSearch />}>
                  <Link to="/painel">Pesquisar Imóveis</Link>
                </Item>

                <Item key="1" icon={<FaBook />}>
                  <Link to="/painel/agendamentos">Agendamentos</Link>
                </Item>
                <Item key="2" icon={<FaUsers />}>
                  <Link to="/painel/clientes"> Clientes </Link>
                </Item>

                <Item key="3" icon={<FaUserPlus />}>
                  <Link to="/painel/cliente/novo"> Novo Cliente </Link>
                </Item>
                <Item key="4" icon={<FaUser />}>
                  <Link to="/painel/conta">Minhas Informações</Link>
                </Item>
                <Item
                  key="99"
                  icon={<FaSignOutAlt />}
                  onClick={handleBrokerLogout}
                >
                  Sair
                </Item>
              </Menu>
            )}
          </DrawerBody>
        </Drawer>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  auth: state.auth.user,
});

export default connect(mapStateToProps)(DrawerComponent);
