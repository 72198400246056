import { Button, Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import InputMask from 'react-input-mask';
import { decorator, states } from 'src/utils';

interface IProps {
  sending?: boolean;
}

const CompanyForm: React.FC<IProps> = (props) => {
  const { Option } = Select;

  return (
    <>
      <Row gutter={16}>
        <Col lg={12} md={12} xs={24} sm={12}>
          <Form.Item
            label="Nome da Empresa"
            name="companyName"
            rules={decorator.companyName}
          >
            <Input placeholder="Insira o nome da empresa..." />
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item label="E-mail" name="email" rules={decorator.email}>
            <Input type="email" placeholder="Insira o e-mail da empresa..." />
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item label="Telefone" name="phone" rules={decorator.phone}>
            <InputMask mask="99 99999-9999">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira o telefone da empresa..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} md={12} xs={24} sm={12}>
          <Form.Item label="CNPJ" name="cnpj" rules={decorator.cnpj}>
            <InputMask mask="99.999.999/9999-99">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira o CNPJ da empresa..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col lg={12} md={12} xs={24} sm={12}>
          <Form.Item
            label="Profissão"
            name={['owner', 'profession']}
            rules={decorator.profession}
          >
            <Input placeholder="Insira a profissão da empresa..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={4} md={4} xs={24} sm={12}>
          <Form.Item
            label="Estado"
            name={['address', 'state']}
            rules={decorator.state}
          >
            <Select>
              {states.map((item) => (
                <Option key={item.key} value={item.uf}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={3} md={3} xs={24} sm={12}>
          <Form.Item
            label="CEP"
            name={['address', 'zipcode']}
            rules={decorator.zipcode}
          >
            <InputMask mask="99999-999">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CEP..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col lg={5} md={5} xs={24} sm={12}>
          <Form.Item
            label="Cidade"
            name={['address', 'city']}
            rules={decorator.city}
          >
            <Input placeholder="Insira a cidade..." />
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item
            label="Bairro"
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
          >
            <Input placeholder="Insira o bairro..." />
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item
            label="Rua"
            name={['address', 'street']}
            rules={decorator.street}
          >
            <Input placeholder="Insira a rua..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={4} md={4} xs={24} sm={12}>
          <Form.Item
            label="Número"
            name={['address', 'number']}
            rules={decorator.streetNumber}
          >
            <Input placeholder="Insira o número da rua" />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} xs={24} sm={12}>
          <Form.Item
            label="Complemento (Opcional)"
            name={['address', 'complement']}
            rules={decorator.complement}
          >
            <Input placeholder="Insira o complemento..." />
          </Form.Item>
        </Col>

        <Col lg={8} md={8} xs={24} sm={12}>
          <Form.Item label="Senha" name="password" rules={decorator.password}>
            <Input.Password placeholder="Insira a senha" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CompanyForm;
