import React from 'react';

const NotFound: React.FC = () => (
  <>
    <p>
      Opa! Parece que você entrou em um terreno abandonado. Clique na logo para
      voltar a páginal inicial!
    </p>
  </>
);

export default NotFound;
