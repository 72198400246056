import styled from 'styled-components';

export const Container = styled.div`
  margin: 15px;
  padding: 30px 10px 10px;
  max-height: 400px;
  text-align: justify;
  overflow-y: scroll;
  h2 {
    text-align: center;
  }
  h3 {
    text-align: left;
  }
`;

export const Title = styled.h2`
  text-align: center;

`