import gql from 'graphql-tag';
import client from './client.auth';

export const OwnerServices = {
  owner: () =>
    client
      .query({
        query: gql`
          query owner {
            me {
              id
              name
              companyName
              birthdate
              phone
              email
              cpf
              cnpj
              active
              owner {
                id
                address {
                  state
                  city
                  state
                  street
                  zipcode
                  number
                  neighborhood
                  complement
                }
                maritalStatus
                profession
                stateRegistration
                properties {
                  id
                  type
                  subtype
                  zone
                  status
                  address {
                    street
                    number
                    neighborhood
                    city
                    state
                    zipcode
                    complement
                    complex
                    latitude
                    longitude
                  }
                  builder
                  images {
                    path
                  }
                  area {
                    backyard
                    frontLot
                    leftLot
                    lotArea
                    otherMeasures
                    privateArea
                    rightLot
                  }
                  caracteristics {
                    balconies
                    bathrooms
                    dependencies
                    garages
                    kitchens
                    livingRooms
                    others
                    rooms
                    suites
                    technicalAreas
                    toilets
                  }
                  values {
                    annualIptu
                    monthlyIptu
                    communityRentPrice
                    communitySalePrice
                    rentPrice
                    salePrice
                    rentExtraTaxes
                    saleExtraTaxes
                  }
                  columns
                  isSold
                }
                createdAt
                updatedAt
                
              }
            }
          }
        `,
      }).then(res => res.data.me),
};