import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { persistStore } from 'redux-persist';

import { store } from '../store';

const errorLink = onError(({ graphQLErrors }: any) => {
  if (graphQLErrors) {
    console.log(graphQLErrors);
    graphQLErrors.map(({ message }: any) => console.log(message));

    graphQLErrors.map(({ code }: any) => {
      if (code === 'UNAUTHENTICATED') {
        persistStore(store)
          .purge()
          .then(() => {
            window.location.reload();
          });
      }
      return true;
    });
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = store.getState().auth.user!.token;
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const uploadLink = createUploadLink({
  uri: 'https://api.embrane.com.br/graphql',
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, uploadLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

export default client;
