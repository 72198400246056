import { combineReducers } from 'redux';
import configReducer from './config/reducers';
import authReducer from './auth/reducers';
import propertyReducer from './property/reducers';
import ownerReducer from './owner/reducers';
import brokerReducer from './broker/reducer';
import appointmentReducer from './appointments/reducer';
import scheduleReducer from './schedule/reducers';
import clientReducer from './clients/reducer';

export default combineReducers({
  config: configReducer,
  auth: authReducer,
  properties: propertyReducer,
  owner: ownerReducer,
  broker: brokerReducer,
  client: clientReducer,
  appointments: appointmentReducer,
  schedules: scheduleReducer,
});
