import React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { decorator, maritalStatus, states } from 'src/utils';
import InputMask from 'react-input-mask';
import { FormContainer } from './styles';

const ClientPersonForm: React.FC = () => {
  const { Item } = Form;
  const { Option } = Select;
  return (
    <FormContainer>
      <Row gutter={16}>
        <Col md={12} lg={12} sm={12} xs={24}>
          <Item label="Nome Completo" name="fullName" rules={decorator.name}>
            <Input
              name="fullName"
              placeholder="Insira o nome completo do cliente..."
            />
          </Item>
        </Col>
        <Col md={6} lg={4} sm={12} xs={24}>
          <Item label="Telefone" name="phone" rules={decorator.phone}>
            <InputMask mask="(99) 99999-9999">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o telefone..." />
              )}
            </InputMask>
          </Item>
        </Col>
        <Col md={6} lg={4} sm={12} xs={24}>
          <Item label="CPF" name="document" rules={decorator.notRequiredCpf}>
            <InputMask mask="999.999.999-99">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CPF..." />
              )}
            </InputMask>
          </Item>
        </Col>
        <Col md={6} lg={4} sm={12} xs={24}>
          <Item label="E-mail" name="email" rules={decorator.notRequiredEmail}>
            <Input type="email" placeholder="Insira o e-mail..." />
          </Item>
        </Col>

        <Col md={6} lg={4} sm={12} xs={24}>
          <Item
            label="Estado Civil"
            name="maritalStatus"
            rules={decorator.maritalStatus}
          >
            <Select
              size="middle"
              placeholder="Insira o Estado Civil do cliente..."
            >
              {maritalStatus.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <h1>Endereço do Cliente:</h1>
      <Row gutter={16}>
        <Col md={6} lg={4} sm={12} xs={24}>
          <Item
            label="CEP"
            name={['address', 'zipcode']}
            rules={decorator.zipcode}
          >
            <InputMask mask="99999-999" name="zipcode">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CEP..." />
              )}
            </InputMask>
          </Item>
        </Col>
        <Col md={6} lg={5} xs={24} sm={12}>
          <Item
            label="Estado"
            name={['address', 'state']}
            rules={decorator.state}
          >
            <Select placeholder="Insira o Estado...">
              {states.map((item) => (
                <Option key={item.key} index={item.key} value={item.uf}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col md={12} lg={5} xs={24} sm={12}>
          <Item
            label="Cidade"
            name={['address', 'city']}
            rules={decorator.city}
          >
            <Input placeholder="Insira a cidade..." name="city" />
          </Item>
        </Col>
        <Col md={12} lg={5} xs={24} sm={12}>
          <Item
            label="Bairro"
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
          >
            <Input placeholder="Insira o bairro..." name="neighborhood" />
          </Item>
        </Col>
        <Col md={12} lg={5} xs={24} sm={12}>
          <Item
            label="Rua"
            name={['address', 'street']}
            rules={decorator.street}
          >
            <Input placeholder="Insira a rua..." name="street" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={4} lg={4} xs={24} sm={24}>
          <Item
            label="Número"
            name={['address', 'number']}
            rules={decorator.streetNumber}
          >
            <Input placeholder="Insira o número..." name="number" />
          </Item>
        </Col>
        <Col md={10} lg={10} xs={24} sm={24}>
          <Item label="Condomínio/Edifício" name={['address', 'complex']}>
            <Input placeholder="Insira o referencial do condomínio/edifício..." />
          </Item>
        </Col>
        <Col md={10} lg={10} xs={24} sm={24}>
          <Item label="Complemento" name={['address', 'complement']}>
            <Input placeholder="Insira o complemento do endereço..." />
          </Item>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default ClientPersonForm;
