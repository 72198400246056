import { Descriptions } from 'antd';
import React from 'react';

interface IProps {
  zone: string;
  type: string;
  subtype: string;
}

const BasicData: React.FC<IProps> = (props) => {
  const { zone, type, subtype } = props;
  const { Item } = Descriptions;

  return (
    <Descriptions title="Informações Básicas" style={{ marginBottom: '35px' }}>
      <Item label="Zona">{zone}</Item>
      <Item label="Tipo"> {type} </Item>
      <Item label="Subtipo"> {subtype} </Item>
    </Descriptions>
  );
};

export default BasicData;
