import Select, { ActionMeta } from 'react-select';
import makeAnimated from 'react-select/animated';
import React, { useEffect, useState } from 'react';
import { IProperty } from 'src/interfaces/property';

import { Container } from './styles';
import { IClient, ISendPDFToClientInput } from 'src/interfaces/client';
import { IReduxState } from 'src/store';
import { fetchClients, sendPDFs } from 'src/store/clients/actions';
import { connect } from 'react-redux';
import { Select as CustomSelect } from 'antd';

interface IPDFObject {
  path: string;
  fileName: string;
}

interface IProps {
  properties?: IProperty[];
  propertiesPdfs?: IPDFObject[];
  fetchClients: () => Promise<IClient[]>;
  sendPDFs: (input: ISendPDFToClientInput) => Promise<IClient[]>;
}
interface ClientArray {
  value: string;
  label: string;
}

const animatedComponent = makeAnimated();

const MenuSelectOptions: React.FC<IProps> = (props) => {
  const { properties, propertiesPdfs } = props;
  const [selectedOptions, setSelectedOptions] = useState<ClientArray[]>([]);
  const [selectedAction, setSelectedAction] = useState('');
  const [pdfFiles, setPdfFiles] = useState<Blob[]>([]);
  const [options, setOptions] = useState([{ value: '', label: '' }]);

  const { Option } = CustomSelect;
  const actionOptions = [
    { value: 'Enviar por e-mail', action: 'email' },
    { value: 'Fazer download', action: 'download' },
  ];

  useEffect(() => {
    props.fetchClients().then((res) => {
      const clientsArray: ClientArray[] = res.map((item) => ({
        value: item.id,
        label: item.fullName,
      }));
      setOptions(clientsArray);
    });

    return () => {
      setOptions([]);
      setSelectedOptions([]);
      setSelectedAction('');
    };
  }, [properties]);

  const handleChange = (newValue: any) => {
    setSelectedOptions(newValue);
  };

  const handleOnSelect = (value: any, option: any) => {
    setSelectedAction(value);
  };

  const handlePDFFiles = () => {
    propertiesPdfs?.forEach((pdf, index) => {
      fetch(pdf?.path).then((res) => {
        res.blob().then((blob) => {
          const downloadLink = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadLink;
          link.setAttribute('download', pdf.fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      });
    });
  };

  const sendPdfsToClients = async () => {
    const clientIds = selectedOptions.map((item: any) => item.value);
    await props
      .sendPDFs({
        clients: clientIds,
        pdfPaths: propertiesPdfs,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {properties && properties.length > 0 ? (
        <>
          <Container>
            <span className="propertiesLength">
              <div className="number">{properties.length}</div>
              Propriedade(s) selecionada(s).
            </span>

            <CustomSelect
              onSelect={handleOnSelect}
              placeholder="Selecione a ação..."
            >
              {actionOptions.map((action, index) => (
                <Option value={action.action} key={index}>
                  {action.value}
                </Option>
              ))}
            </CustomSelect>
            {selectedAction === 'email' && (
              <Select
                noOptionsMessage={() => 'Não há clientes'}
                name="clients"
                className="selector"
                value={selectedOptions}
                options={options}
                closeMenuOnSelect={false}
                components={animatedComponent}
                onChange={handleChange}
                placeholder="Selecione os clientes..."
                isMulti
                menuPlacement="top"
              />
            )}
            {selectedAction === 'download' ? (
              <button onClick={() => handlePDFFiles()}>Baixar PDFs</button>
            ) : selectedAction === 'email' ? (
              <button onClick={() => sendPdfsToClients()}>
                Enviar Imóveis
              </button>
            ) : null}
          </Container>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  clients: state.client.clients,
  propertiesPdfs: state.properties?.propertiesPdf,
});

export default connect(mapStateToProps, { fetchClients, sendPDFs })(
  MenuSelectOptions
);
