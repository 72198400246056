import { Dispatch } from 'react';
import { ISignUpBrokerInput } from 'src/interfaces/broker';
import { ISignUpOwnerInput } from 'src/interfaces/owner';
import { ILoginInput } from 'src/interfaces/user';
import { userServices } from 'src/services/user';
import { forms } from 'src/utils';
import { AuthActionTypes, SIGNUP_BROKER, SIGNUP_OWNER, SIGN_IN_BROKER, SIGN_IN_OWNER } from './types';



export const ownerLogin = (input: ILoginInput) => (dispatch: Dispatch<AuthActionTypes>) => {
  return userServices.ownerLogin(input).then(res => {
    dispatch({
      type: SIGN_IN_OWNER,
      payload: res,
    })
    return res;
  }).catch(err => {
    forms.errors(err);
    throw Error;
  })
}

export const brokerLogin = (input: ILoginInput) => (dispatch: Dispatch<AuthActionTypes>) => {
  return userServices.brokerLogin(input).then(res => {
    dispatch({
      type: SIGN_IN_BROKER,
      payload: res,
    })
    return res;
  }).catch(err => {
    forms.errors(err);
    throw Error;
  })
}

export const signUpBroker = (input: ISignUpBrokerInput) => (dispatch: Dispatch<AuthActionTypes>) => {
  return userServices.signUpBroker(input).then((res) => {
    dispatch({
      type: SIGNUP_BROKER,
      payload: res,
    })
    return res;
  }).catch((err) => {
    forms.errors(err);
    throw Error;
  }) 
}

export const signUpOwner = (input: ISignUpOwnerInput) => (dispatch: Dispatch<AuthActionTypes>) => {
  return userServices.signUpOwner(input).then((res) => {
    dispatch({
      type: SIGNUP_OWNER,
      payload: res
    })
    return res;
  }).catch((err) => {
    forms.errors(err);
    throw Error;
  })
}