import { Button, Form, notification, Row } from 'antd';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'src/components';
import { Redirect } from 'react-router-dom';
import { IClient, ICreateClientInput } from 'src/interfaces/client';

import { createClient } from 'src/store/clients/actions';
import ClientPersonForm from './personForm';
import ClientCompanyForm from './companyForm';
import { store } from 'src/store';

interface IProps {
  createClient: (input: ICreateClientInput) => Promise<IClient>;
}

const CreateClient: React.FC<IProps> = (props) => {
  const [sending, setSending] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [clientType, setClientType] = useState('');
  const { Group } = Radio;

  const [form] = Form.useForm();

  const handleClientType = (e: RadioChangeEvent) => {
    setClientType(e.target.value);
  };

  const onFinish = (values: any) => {
    setSending(true);
    props
      .createClient(values)
      .then((res) => {
        if (res) {
          notification.success({
            message: 'Cliente cadastrado com sucesso!',
          });
          setRedirect(`/painel/clientes/`);
        }
      })
      .catch((err) => {
        notification.error({
          message:
            'Occorreu um erro ao cadastrar o novo cliente, tente novamente mais tarde ou contate o nosso suporte.',
        });
      });

    setSending(false);
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <Card
        title="Novo Cliente"
        subtitle="Preencha o formulário abaixo para cadastrar um novo cliente."
      >
        <Group onChange={handleClientType} value={clientType}>
          <Radio value="person" checked={clientType === 'person'}>
            Pessoa Física
          </Radio>
          <Radio value="company" checked={clientType === 'company'}>
            Pessoa Jurídica
          </Radio>
        </Group>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          {clientType === 'person' ? <ClientPersonForm /> : null}
          {clientType === 'company' ? <ClientCompanyForm /> : null}
          {!!clientType ? (
            <Row align="middle" justify="center">
              <Button
                className="btn-success"
                htmlType="submit"
                loading={sending}
              >
                Cadastrar Cliente
              </Button>
            </Row>
          ) : null}
        </Form>
      </Card>
    </>
  );
};

export default connect(undefined, { createClient })(CreateClient);
