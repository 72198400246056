import { Button, Form, Modal, notification, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { GoBack } from 'src/components';
import { OwnerWrapper, OwnerContainer } from './ownerSignup.styles';
import CompanyForm from './companyForm.component';
import PersonForm from './personForm.component';
import { signUpOwner } from 'src/store/auth/actions';

import { IProps } from './ownerSignup.types';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Contract from 'src/components/Contract/contract.component';
import { decorator } from 'src/utils';

const OwnerSignupScreen: React.FC<IProps> = (props) => {
  const [checked, setChecked] = useState('');
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
    setCheckbox(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCheckbox(false);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    props
      .signUpOwner(values)
      .then(() => {
        notification.success({
          message: 'Cadastro efetuado com sucesso!',
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onChange = (e: RadioChangeEvent) => {
    setChecked(e.target.value);
  };

  return (
    <OwnerWrapper>
      <GoBack />
      <h1>Seja Bem-vindo!</h1>
      <h2>
        Escolha o tipo de Pessoa (Jurídica ou Física) e preencha o formulário
        para prosseguir
      </h2>
      <OwnerContainer>
        <Row justify="center" style={{ padding: '10px 0 25px 0' }}>
          <Radio.Group onChange={onChange} value={checked}>
            <Radio value="person" checked={checked === 'person'}>
              Pessoa Física
            </Radio>
            <Radio value="company" checked={checked === 'company'}>
              Pessoa Jurídica
            </Radio>
          </Radio.Group>
        </Row>
        <Form onFinish={onFinish} layout="vertical">
          {checked === 'person' ? <PersonForm sending={loading} /> : null}
          {checked === 'company' ? <CompanyForm sending={loading} /> : null}
          {checked !== '' ? (
            <>
              <Row gutter={16} align="middle" justify="center">
                <Checkbox onClick={showModal} checked={checkbox}>
                  Concordo com os <b>Termos de Uso</b> e aceito a
                  <b> Política de Privacidade.</b>
                </Checkbox>
                <Modal
                  centered
                  style={{ color: '#000' }}
                  visible={modalVisible}
                  onOk={handleOk}
                  cancelText="Não Concordo"
                  okText="Concordo"
                  okButtonProps={{ className: 'ant-btn' }}
                  cancelButtonProps={{ className: 'ant-btn' }}
                  onCancel={handleCancel}
                >
                  <Contract />
                </Modal>
              </Row>
              <Row gutter={16} align="middle" justify="center">
                <Button
                  htmlType="submit"
                  className="btn-success"
                  disabled={!checkbox}
                >
                  Avançar
                </Button>
              </Row>
            </>
          ) : null}
        </Form>
      </OwnerContainer>
    </OwnerWrapper>
  );
};

export default connect(undefined, { signUpOwner })(OwnerSignupScreen);
