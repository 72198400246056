import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 94%;
  flex-direction: column;
  svg {
    width: 240px;
    height: 240px;
  }
  @media (max-width: 420px) {
    padding: 45px;
    min-width: 100%;
  }
  div {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  a {
    font-size: 36px;
    margin: 0 auto;
    position: relative;
    font-family: Orbitron, sans-serif;
    color: var(--app-ternary-color);
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
      background: #333333;
      visibility: hidden;
      border-radius: 5px;
      transform: scaleX(0);
      transition: 0.25s linear;
    }
    &:hover:before,
    :focus:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
`;
