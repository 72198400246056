import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'src/components';
import { IReduxState, store } from 'src/store';

import { IProps } from './main.types';

const OwnerMainScreen: React.FC<IProps> = (props) => {
  const { user } = props;

  return (
    <Card title="Painel">
      <p>
        Olá <b>{user?.name || user?.companyName}!</b> Seja bem-vindo(a) ao
        painel do proprietário da Embrane. Navegue no menu ao lado para
        cadastrar e ver os status dos seus imóveis.
      </p>
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(OwnerMainScreen);
