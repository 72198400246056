import React from 'react';

import { Wrapper, Text } from './footer.styles';

import packageJson from '../../../../../package.json';

const FooterComponent: React.FC = () => (
  <Wrapper>
    <Text>
      &copy; {new Date().getFullYear()} - Embrane - Todos os Direitos Reservados
    </Text>
  </Wrapper>
);

export default FooterComponent;
