import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 6rem 6rem 1rem 6rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: auto;
  width: auto;
`;

export const MenuContainer = styled.div`
  width: 50px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1.7em;
    color: #fff;
    padding: 10px;
    cursor: pointer;
  }
`;

export const LogoContainer = styled.div`
  width: 90%;
  text-align: center;
  h1 {
    margin: 0;
    a {
      color: #fff;
    }
  }
`;
