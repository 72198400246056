import { notification, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Card } from 'src/components';
import { IReduxState } from 'src/store';
import { fetchOwner } from 'src/store/owner/actions';

import { IParams, IProps } from './account.types';
import OwnerData from './components/ownerData.component.';

const AccountScreen: React.FC<IProps> = (props) => {
  const [redirect, setRedirect] = useState('');
  const [loading, setLoding] = useState(false);
  const { owner } = props;

  useEffect(() => {
    const id = (props.match.params as IParams).id;

    props.fetchOwner(id).catch(() => {
      notification.error({
        message: 'Ocorreu um erro, por favor tente novamente mais tarde.',
      });
    });
  }, []);

  return (
    <>
      {!!redirect && <Redirect to={redirect} />}
      {!!owner && !loading ? (
        <>
          <OwnerData owner={owner} />
        </>
      ) : (
        <Card title="Minhas informações">
          <Skeleton active paragraph={{ rows: 6 }} />
        </Card>
      )}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  owner: state.owner.owner,
});

export default connect(mapStateToProps, { fetchOwner })(AccountScreen);
