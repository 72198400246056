import styled from 'styled-components';
import { colors } from 'src/utils';

export const Container = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: left;
  gap: 2rem;
  align-items: center;
  bottom: 10%;
  left: 50%;
  color: var(--app-secondary-color);
  transform: translate(-45%, 0);
  padding: 0.5rem;
  border-radius: 20px;
  background: var(--app-quarternary-color);

  span {
    white-space: nowrap;
  }
  .propertiesLength {
    display: flex;
    align-items: center;
  }
  .number {
    background: ${colors.deepPurple[900]};
    border-radius: 50%;
    padding: 0 8px;
    margin-right: 8px;
  }
  .selector {
    width: 280px;
  }
  button {
    background: ${colors.deepPurple[900]};
    border: none;
    border-radius: 20px;
    padding: 0.25rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    white-space: nowrap;
    transition: 0.3s all ease-in;
    cursor: pointer;
    &:hover {
      background: ${colors.deepPurple[700]};
    }
  }
`;
