import { ISchedule } from "src/interfaces/schedule";

export const FETCH_SCHEDULES = "FETCH_SCHEDULES"



export interface IReducerScheduleState {
  schedules?: ISchedule[];
}

export interface IFetchSchedules {
  type: typeof FETCH_SCHEDULES;
  payload: ISchedule[];
}

export type ScheduleActionTypes = IFetchSchedules;