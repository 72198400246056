import React from 'react';
import { IProperty } from 'src/interfaces/property';
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  Image,
  View,
} from '@react-pdf/renderer';

interface IProps {
  property?: IProperty;
}

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffff',
    padding: '12px 24px',
  },
  titleInfo: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#2D2D2D',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  basicInfoTitle: {
    fontSize: '15px',
    color: '#000',
    fontWeight: 700,
    marginBottom: '12px',
  },
  basicInfoContainer: {
    fontSize: '14px',
    color: '#4d4d4d',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: '8px',
    marginBottom: '16px',
  },
  basicInfoWrapper: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: '12px',
  },
  basicInfoText: {
    width: '100%',
  },
  addressContainer: {
    fontSize: '14px',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: '8px',
    marginBottom: '16px',

    color: '#4d4d4d',
  },
  addressInfoTitle: {
    fontSize: '15px',
    color: '#000',
    fontWeight: 700,
    marginBottom: '12px',
  },

  addressInfoWrapper: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: '12px',
  },
  firstImage: {
    border: '1px solid rgba(200, 200, 200, 1)',
    marginBottom: '6px',
    height: '300px',
  },
  firstImageObject: {
    width: '100%',
    objectFit: 'cover',
  },
  addressItem: {
    width: '100%',
  },
  caracteristicsContainer: {
    fontSize: '14px',
    color: '#4d4d4d',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: '8px',
    marginBottom: '16px',
  },
  caracteristicsWrapper: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: '12px',
  },
  caracteristicsItem: {
    width: '100%',
  },
  areaContainer: {
    fontSize: '14px',
    color: '#4d4d4d',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: '8px',
    marginBottom: '12px',
  },
  areaTitle: {
    fontSize: '15px',
    color: '#000',
    fontWeight: 700,
    marginBottom: '12px',
  },
  areaWrapper: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: '12px',
  },
  areaItem: {
    width: '100%',
  },
  otherImages: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    border: '1px solid rgba(200, 200, 200, 1)',
    marginRight: '12px',
    marginBottom: '8px',
  },
  image: {
    objectFit: 'cover',
    width: 150,
    height: 150,
  },
  oddImage: {},
});

const PropertyPDF: React.FC<IProps> = (props) => {
  const { property } = props;
  const remainingImages = property?.images.slice(1);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.firstImage}>
          <Image
            style={styles.firstImageObject}
            src={property?.images[0]?.path!}
          />
        </View>
        <View style={styles.titleInfo}>
          <Text>
            {property?.type} {property?.address.state}-
            {property?.code?.toString().padStart(3, '0')}
          </Text>
        </View>
        <View style={styles.basicInfoContainer}>
          <Text style={styles.basicInfoTitle}>Informações Básicas:</Text>
          <View style={styles.basicInfoWrapper}>
            <Text style={styles.basicInfoText}>Zona: {property?.zone}</Text>

            <Text style={styles.basicInfoText}>
              Subtipo: {property?.subtype}
            </Text>
            <Text style={styles.basicInfoText}>
              Negociação: {property?.values.salePrice ? 'Venda' : 'Locação'}
            </Text>
          </View>
          <View style={styles.basicInfoWrapper}>
            <Text style={styles.basicInfoText}>
              Valor:{' '}
              {property?.values.salePrice
                ? property?.values.salePrice?.toLocaleString('pt-br', {
                    currency: 'BRL',
                    style: 'currency',
                  })
                : property?.values.rentPrice?.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                  })}
            </Text>
            <Text style={styles.basicInfoText}>
              Condomínio:{' '}
              {property?.values.communitySalePrice
                ? property?.values.communitySalePrice?.toLocaleString('pt-br', {
                    currency: 'BRL',
                    style: 'currency',
                  })
                : property?.values.communityRentPrice
                ? property?.values.communityRentPrice?.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                  })
                : ' -'}
            </Text>

            <Text style={styles.basicInfoText}>
              IPTU:{' '}
              {property?.values.annualIptu
                ? property?.values.annualIptu?.toLocaleString('pt-br', {
                    currency: 'BRL',
                    style: 'currency',
                  })
                : property?.values.monthlyIptu?.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                  })}{' '}
              {property?.values.annualIptu ? 'Anual' : 'Mensal'}
            </Text>
          </View>
          <View style={styles.basicInfoWrapper}>
            <Text style={styles.basicInfoText}>
              Outras Taxas:{' '}
              {property?.values.rentExtraTaxes
                ? property?.values.rentExtraTaxes
                : property?.values.saleExtraTaxes || '-'}
            </Text>
          </View>
        </View>
        <View style={styles.addressContainer}>
          <Text style={styles.addressInfoTitle}> Endereço: </Text>

          <View style={styles.addressInfoWrapper}>
            <Text style={styles.addressItem}>
              CEP: {property?.address.zipcode}
            </Text>
            <Text style={styles.addressItem}>
              Estado: {property?.address.state}
            </Text>
            <Text style={styles.addressItem}>
              Cidade: {property?.address.city}
            </Text>
          </View>

          <View style={styles.addressInfoWrapper}>
            <Text style={styles.addressItem}>
              Bairro: {property?.address.neighborhood}
            </Text>
            <Text style={styles.addressItem}>
              Condomínio: {property?.address.complex || '-'}
            </Text>
            <Text style={styles.addressItem}>
              Rua: {property?.address.street}
            </Text>
          </View>

          <View style={styles.addressInfoWrapper}>
            <Text style={styles.addressItem}>
              Complemento: {property?.address.complement || '-'}
            </Text>
            <Text style={styles.addressItem}>
              Latitude: {property?.address.latitude || '-'}
            </Text>
            <Text style={styles.addressItem}>
              Longitude: {property?.address.longitude || '-'}
            </Text>
          </View>
        </View>
        <View style={styles.caracteristicsContainer}>
          <Text style={styles.addressInfoTitle}>Caracteristicas:</Text>
          <View style={styles.caracteristicsWrapper}>
            <Text style={styles.caracteristicsItem}>
              Quartos: {property?.caracteristics?.rooms || '-'}
            </Text>

            <Text style={styles.caracteristicsItem}>
              Banheiros: {property?.caracteristics?.bathrooms || '-'}
            </Text>
            <Text style={styles.caracteristicsItem}>
              Suítes: {property?.caracteristics?.suites || '-'}
            </Text>
          </View>
          <View style={styles.caracteristicsWrapper}>
            <Text style={styles.caracteristicsItem}>
              Salas: {property?.caracteristics?.livingRooms || '-'}
            </Text>
            <Text style={styles.caracteristicsItem}>
              Cozinhas: {property?.caracteristics?.kitchens || '-'}
            </Text>
            <Text style={styles.caracteristicsItem}>
              Lavabos: {property?.caracteristics?.toilets || '-'}
            </Text>
          </View>
          <View style={styles.caracteristicsWrapper}>
            <Text style={styles.caracteristicsItem}>
              Dependências: {property?.caracteristics?.dependencies || '-'}
            </Text>

            <Text style={styles.caracteristicsItem}>
              Áreas Técnicas: {property?.caracteristics?.technicalAreas || '-'}
            </Text>
            <Text style={styles.caracteristicsItem}>
              Garagens: {property?.caracteristics?.garages || '-'}
            </Text>
          </View>
          <View style={styles.caracteristicsWrapper}>
            <Text style={styles.caracteristicsItem}>
              Outras Características:{' '}
              {property?.caracteristics?.others
                ?.map((item) => item)
                .join(', ') || '-'}
            </Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.areaContainer}>
          <Text style={styles.areaTitle}>Área do Imóvel:</Text>
          <View style={styles.areaWrapper}>
            <Text style={styles.areaItem}>
              Área Privativa:{' '}
              {property?.area?.privateArea?.toLocaleString('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
              })}{' '}
              {property?.area?.privateArea ? 'm²' : '-'}
            </Text>
            <Text style={styles.areaItem}>
              Área do Lote:{' '}
              {property?.area?.lotArea?.toLocaleString('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
              })}{' '}
              {property?.area?.lotArea ? 'm²' : '-'}
            </Text>
          </View>
        </View>
        <View style={styles.areaContainer}>
          <Text style={styles.areaTitle}>Características e Confrontações:</Text>
          <View style={styles.areaWrapper}>
            <Text style={styles.areaItem}>
              Frente:{' '}
              {property?.area?.frontLot?.toLocaleString('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
              })}{' '}
              {property?.area?.frontLot ? 'm' : '-'}
            </Text>

            <Text style={styles.areaItem}>
              Lote Direito:{' '}
              {property?.area?.leftLot?.toLocaleString('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
              })}{' '}
              {property?.area?.leftLot ? 'm' : '-'}
            </Text>
            <Text style={styles.areaItem}>
              Lote Esquerdo:{' '}
              {property?.area?.rightLot?.toLocaleString('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
              })}{' '}
              {property?.area?.rightLot ? 'm' : '-'}
            </Text>
          </View>
          <View style={styles.areaWrapper}>
            <Text style={styles.areaItem}>
              Fundos:{' '}
              {property?.area?.backyard?.toLocaleString('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
              })}{' '}
              {property?.area?.backyard ? 'm' : '-'}
            </Text>
          </View>
        </View>
        <View style={styles.areaContainer}>
          <Text style={styles.areaTitle}>Outras Imagens:</Text>
        </View>
        <View style={styles.otherImages}>
          {remainingImages?.map((image, index) => (
            <View key={index} style={styles.imageContainer}>
              <Image style={styles.image} src={image.path} />
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PropertyPDF;
