import gql from 'graphql-tag';
import client from './client';
import { ILoginInput } from 'src/interfaces/user';
import { ISignUpBrokerInput } from 'src/interfaces/broker';
import { ISignUpOwnerInput } from 'src/interfaces/owner';

export const userServices = {
  ownerLogin: (input: ILoginInput) =>
    client
      .mutate({
        mutation: gql`
          mutation ownerLogin($input: LoginInput!) {
            ownerLogin(input: $input) {
              id
              name
              companyName
              token
              role
              email
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.ownerLogin),
  
  brokerLogin: (input: ILoginInput) =>
    client
      .mutate({
        mutation: gql`
          mutation brokerLogin($input: LoginInput!) {
            brokerLogin(input: $input) {
              id
              name
              companyName
              role
              token
              email
            }
          }
        `,
        variables: { input },
      }).then((res) => res.data.brokerLogin),


  signUpBroker: (input: ISignUpBrokerInput) =>
    client
      .mutate({
        mutation: gql`
          mutation createBroker($input: CreateBrokerInput!) {
            createBroker(input: $input) {
              id
              name
              token
              companyName
              email
              role
              birthdate
              phone
              cpf
              cnpj
              broker {
                id
                creci
                status
                stateRegistration
                address {
                zipcode
                state
                city
                neighborhood
                street
                number
                complement
                }
              }
              createdAt
              updatedAt
            }
          }
        `,
        variables: { input },
      }).then((res) => res.data.createBroker),

  signUpOwner: (input: ISignUpOwnerInput) =>
    client
      .mutate({
        mutation: gql`
          mutation createOwner($input: CreateOwnerInput!) {
            createOwner(input: $input) {
              id
              token
              name
              companyName
              email
              role
              birthdate
              phone
              cnpj
              cpf
              owner {
                address {
                  city
                  complement
                  neighborhood
                  number
                  state
                  street
                  zipcode
                }
                maritalStatus
                id
                profession
                stateRegistration
                properties {
                  id
                }
              }
              createdAt
              updatedAt
            }
          }
        `,
        variables: { input }
      }).then(res => res.data.createOwner)
};
