import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  LOGOUT_USER,
  IReducerAuthState,
  AuthActionTypes,
  SIGN_IN_OWNER,
  SIGN_IN_BROKER,
  FETCH_DATA,
  SIGNUP_BROKER,
} from './types';

const initialState: IReducerAuthState = {
  user: undefined,
};

const persistConfig = {
  storage,
  key: 'auth',
  whitelist: ['token', 'user'],
};

const reducers = (state = initialState, action: AuthActionTypes) => {
  switch (action.type) {
    case SIGN_IN_OWNER:
      return {
        ...state,
        user: action.payload,
      };
    case SIGN_IN_BROKER:
      return {
        ...state,
        user: action.payload,
      };

    case FETCH_DATA:
      return state

    // case CREATE_OWNER:
    //   return {
    //     ...state,
    //     user: { ...action.payload}
    //   };

    case SIGNUP_BROKER:
      return {
        ...state,
        user: action.payload,
      };

    case LOGOUT_USER:
      return {
        ...state,
        user: undefined,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
