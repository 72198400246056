import { Descriptions } from 'antd';
import React from 'react';
import { IValues } from 'src/interfaces/property';

interface IProps {
  values: IValues;
}

const ValuesData: React.FC<IProps> = (props) => {
  const { values } = props;
  const { Item } = Descriptions;

  return (
    <Descriptions
      title="Valores e taxas do imóvel"
      layout="vertical"
      size="middle"
    >
      {values.salePrice ? (
        <>
          <Item label="Preço de Venda" labelStyle={{ fontWeight: 'bold' }}>
            {values.salePrice?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Item>
          <Item label="Valor do Condomínio" labelStyle={{ fontWeight: 'bold' }}>
            {values.communitySalePrice?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }) || 'Não Informado.'}
          </Item>
          <Item label="IPTU (Anual)" labelStyle={{ fontWeight: 'bold' }}>
            {values.annualIptu?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }) || 'Não Informado.'}
          </Item>
          <Item label="Outras Taxas" labelStyle={{ fontWeight: 'bold' }}>
            {values.saleExtraTaxes || 'Não Informado.'}
          </Item>
        </>
      ) : (
        <>
          <Item label="Preço de Locação">
            {values.rentPrice?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Item>
          <Item label="Valor do Condomínio">
            {values.communityRentPrice?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }) || 'Não Informado'}
          </Item>

          <Item label="IPTU (Mensal)">
            {values.monthlyIptu?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }) || 'Não Informado'}
          </Item>
          <Item label="Outras Taxas">
            {values.rentExtraTaxes || 'Não Informado'}
          </Item>
        </>
      )}
    </Descriptions>
  );
};

export default ValuesData;
