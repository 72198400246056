import { notification, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Card } from 'src/components';
import { table } from 'src/utils';
import { IReduxState } from 'src/store';
import { fetchOwner } from 'src/store/owner/actions';

import { IParams, IProps } from './types/properties.types';

const PendentsScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const { owner } = props;

  const properties = owner && owner.owner.properties;

  const pendents = properties?.filter(
    (property) => property.status === 'pendent'
  );

  useEffect(() => {
    const id = (props.match.params as IParams).id;

    props.fetchOwner(id).catch(() => {
      return notification.error({
        message:
          'Ocorreu um erro ao atualizar os imóveis, por favor tente novamente mais tarde.',
      });
    });
    setLoading(false);
  }, []);

  const dataSource =
    pendents &&
    pendents.map((pendent) => ({
      key: pendent.id,
      salePrice:
        pendent.values.salePrice?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }) || 'Não Informado.',
      rentPrice:
        pendent.values.rentPrice?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }) || 'Não Informado.',
      type: pendent.type,
      subtype: pendent.subtype,
      zone: pendent.zone,
      state: pendent.address.state,
    }));

  const columns = [
    {
      title: 'Preço de venda',
      key: 'salePrice',
      dataIndex: 'salePrice',
    },
    {
      title: 'Preço de Locação',
      key: 'rentPrice',
      dataIndex: 'rentPrice',
    },
    {
      title: 'Tipo do Imóvel',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Subtípo do Imóvel',
      key: 'subtype',
      dataIndex: 'subtype',
    },
    {
      title: 'Zona',
      key: 'zone',
      dataIndex: 'zone',
    },
    {
      title: 'Estado',
      key: 'state',
      dataIndex: 'state',
    },
  ];

  return (
    <Card
      title="Imóveis pendentes"
      subtitle="Veja aqui seus imóveis pendentes de aprovação"
    >
      <Table
        scroll={{ x: 500 }}
        dataSource={dataSource}
        {...table}
        locale={{
          emptyText: 'Não há nenhum imóvel pendente para aprovação no sistema.',
        }}
        columns={columns}
      />
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  owner: state.owner.owner,
});

export default connect(mapStateToProps, { fetchOwner })(PendentsScreen);
