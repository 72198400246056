import styled from 'styled-components';

export const Container = styled.div`
  -webkit-transition: all 0.5s ease-in-out;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  margin-bottom: 30px;
  margin-top: 1.5rem;
  transition: all 0.5s ease-in-out;
  width: 100%;
  word-wrap: break-word;
`;

export const CardHeader = styled.div`
  background-color: transparent;
  border-bottom: 1px solid #1e1d1f;
  margin: 0;
  padding: 15px 25px;
  position: relative;

  &:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
`;

export const Title = styled.h5`
  color: #1e1d1f;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.1;
  margin: 0 10px 0 0;
  position: relative;

  &:after {
    background-color: #111;
    content: '';
    height: 22px;
    left: -26px;
    position: absolute;
    top: 0;
    width: 4px;
  }
`;

export const Subtitle = styled.p`
  color: #666;
  font-size: 13px;
  margin: 0;
`;

export const CardBody = styled.div`
  padding: 30px;
`;
