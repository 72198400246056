import { Button, Divider, Tooltip } from 'antd';
import React, { useState } from 'react';
import { FaBookmark } from 'react-icons/fa';
import { connect } from 'react-redux';

import { Card } from 'src/components';
import { IReduxState } from 'src/store';
import ImagesGallery from 'src/components/Carousel/carousel.component';
import { IRole } from 'src/interfaces/misc';
import { IProperty } from 'src/interfaces/property';

import Appointment from '../appointment/appointment.component';
import AddressData from './address.component';
import AreaData from './area.component';
import BasicData from './basic.component';
import CaracteristicsData from './caracteristics.component';
import DocumentsData from './documents.component';
import ValuesData from './values.component';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import PropertyPDF from 'src/components/propertyPDF';
import { PrintPropertyButton } from '../styles/showProperty.styles';
import { AiOutlineFilePdf } from 'react-icons/ai';

export interface IProps {
  property: IProperty;
  role?: IRole;
}

const Data: React.FC<IProps> = (props) => {
  const { property, role } = props;
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const renderPdf = (
    <PDFDownloadLink
      fileName={`${property.code}-${property.type}.pdf`}
      document={<PropertyPDF property={property} />}
    >
      {({ blob, error, url, loading }) => (
        <PrintPropertyButton disabled={loading}>
          {loading ? (
            'Carregando'
          ) : (
            <>
              {}
              <a target="_blank" href={url!}>
                <AiOutlineFilePdf /> Imprimir
              </a>
            </>
          )}
        </PrintPropertyButton>
      )}
    </PDFDownloadLink>
  );

  return (
    <Card
      title="Dados do Imóvel"
      subtitle="Veja os dados do Imóvel abaixo."
      tooltip="Imprimir"
      button={renderPdf}
    >
      <ImagesGallery images={property.images} />

      <BasicData
        zone={property.zone}
        type={property.type}
        subtype={property.subtype}
      />
      {role === 'broker' ? (
        <Tooltip title="Agendar Visita" style={{ position: 'sticky' }}>
          <Button
            className="btn-fab"
            type="primary"
            about="Agendar visita"
            icon={<FaBookmark />}
            onClick={showModal}
          />
        </Tooltip>
      ) : null}
      <Appointment
        modalVisible={modalVisible}
        closeModal={closeModal}
        propertyId={property.id}
      />

      <Divider />
      <AddressData address={property.address} />

      {property.type !== 'Terreno' ? (
        <>
          <Divider />
          <CaracteristicsData
            caracteristics={property.caracteristics}
            type={property.type}
          />
        </>
      ) : null}
      <Divider />
      <ValuesData values={property.values} />

      <Divider />
      <AreaData area={property.area} />

      <Divider />
      <DocumentsData
        scripture={property.scripture}
        subscription={property.subscription}
        registration={property.registration}
        car={property.car}
        ccir={property.ccir}
        itr={property.itr}
      />
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  role: state.auth.user?.role,
});

export default connect(mapStateToProps)(Data);
