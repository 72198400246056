import { IArea, IProperty, IValues } from 'src/interfaces/property';

export const FETCH_PROPERTIES = 'FETCH_PROPERTIES';
export const FETCH_PROPERTY = 'FETCH_PROPERTY';
export const CREATE_PROPERTY = 'CREATE_PROPERTY';
export const CLEAR_PROPERTIES = 'CLEAR_PROPERTIES';
export const MARKED_PROPERTIES = 'MARKED_PROPERTIES';
export const REMOVE_MARKED_PROPERTIES = 'REMOVE_MARKED_PROPERTIES';
export const CLEAR_MARKED_PROPERTIES = 'CLEAR_MARKED_PROPERTIES';
export const FILTERED_PROPERTIES = 'FILTERED_PROPERTIES';

export const GET_PDF = 'GET_PDF';
export const REMOVE_PDF = 'REMOVE_PDF';
export const CLEAR_PDFS = 'CLEAR_PDFS';

export const STORE_VALUES = 'STORE_VALUES';
export const STORE_AREA = 'STORE_AREA';
export const SCRIPTURE = 'SCRIPTURE';
export const REGISTRATION = 'REGISTRATION';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const CAR = 'CAR';
export const CCIR = 'CCIR';
export const ITR = 'ITR';
export const IMAGES = 'IMAGES';

export interface IReducerPropertyState {
  property?: IProperty;
  properties?: IProperty[];
  markedProperties?: IProperty[];
  propertiesPdf?: any[];
  filteredProperties?: IProperty[];
  values?: IValues;
  area?: IArea;
  images?: any[];
  scripture?: any;
  registration?: any;
  subscription?: any;
  car?: any;
  ccir?: any;
  itr?: any;
}

export interface IFetchProperty {
  type: typeof FETCH_PROPERTY;
  payload: IProperty;
}

export interface ICreateProperty {
  type: typeof CREATE_PROPERTY;
  payload: IProperty;
}
export interface IFetchProperties {
  type: typeof FETCH_PROPERTIES;
  payload: IProperty[];
}

export interface IClearProperty {
  type: typeof CLEAR_PROPERTIES;
}

export interface IMarkedProperties {
  type: typeof MARKED_PROPERTIES;
  payload: IProperty;
}

export interface IFilteredProperties {
  type: typeof FILTERED_PROPERTIES;
  payload: IProperty[];
}

export interface IRemoveMarkedProperty {
  type: typeof REMOVE_MARKED_PROPERTIES;
  payload: IProperty;
}

export interface IClearMarkedProperties {
  type: typeof CLEAR_MARKED_PROPERTIES;
}

export interface IStoreValues {
  type: typeof STORE_VALUES;
  payload: IValues;
}

export interface IStoreArea {
  type: typeof STORE_AREA;
  payload: IArea;
}

export interface IScripture {
  type: typeof SCRIPTURE;
  payload: any;
}

export interface ISubscription {
  type: typeof SUBSCRIPTION;
  payload: any;
}

export interface IRegistration {
  type: typeof REGISTRATION;
  payload: any;
}

export interface ICar {
  type: typeof CAR;
  payload: any;
}

export interface ICcir {
  type: typeof CCIR;
  payload: any;
}

export interface IItr {
  type: typeof ITR;
  payload: any;
}

export interface IImages {
  type: typeof IMAGES;
  payload: any;
}

export interface IGetPdf {
  type: typeof GET_PDF;
  payload: any;
}

export interface IRemovePdf {
  type: typeof REMOVE_PDF;
  payload: any;
}

export interface IClearPDfs {
  type: typeof CLEAR_PDFS;
}

export type PropertyActionTypes =
  | IFetchProperties
  | IFetchProperty
  | ICreateProperty
  | IStoreValues
  | IStoreArea
  | IScripture
  | ISubscription
  | IClearProperty
  | IRegistration
  | ICar
  | ICcir
  | IItr
  | IImages
  | IGetPdf
  | IRemovePdf
  | IClearPDfs
  | IMarkedProperties
  | IRemoveMarkedProperty
  | IClearMarkedProperties
  | IFilteredProperties;
