import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from 'src/screens/error/404/404.component';

import { IProps } from './client.type';
import OwnerMainScreen from 'src/screens/owner/main/owner.main.component';
import BrokerMainScreen from 'src/screens/broker/main/broker.main.component';
import CreatePropertyScreen from 'src/screens/properties/create.property.component';
import PropertiesScreen from 'src/screens/properties/properties.component';
import PendentsScreen from 'src/screens/properties/pendent.component';
import AccountScreen from 'src/screens/owner/account/account.component';
import ShowPropertyScreen from 'src/screens/properties/show.property.component';
import AppointmentsScreen from 'src/screens/broker/appointments/appointments.component';
import BrokerAccount from 'src/screens/broker/account/account.component';
import Clients from 'src/screens/broker/clients';
import createClient from 'src/screens/broker/clients/createClient';
import Client from 'src/screens/broker/clients/client';
import Playground from 'src/screens/playground';

export default class ClientRoutes extends React.PureComponent<IProps> {
  private ClientRoute = ({ component: Component, ...rest }: any) => {
    const hasAccess = !!this.props.user;
    return (
      <Route
        {...rest}
        render={(propsComponent) => {
          return hasAccess ? (
            <Component {...propsComponent} />
          ) : (
            <Redirect to={{ pathname: '/' }} />
          );
        }}
      />
    );
  };

  public render() {
    const { user } = this.props;
    const ClientRoute = this.ClientRoute;

    return (
      <>
        {this.props.user?.role === 'broker' ? (
          <Switch>
            <ClientRoute path="/painel/" exact component={BrokerMainScreen} />

            <ClientRoute
              path="/painel/imovel/:id"
              exact
              component={ShowPropertyScreen}
            />

            <ClientRoute
              path="/painel/agendamentos"
              exact
              component={AppointmentsScreen}
            />

            <ClientRoute path="/painel/clientes" exact component={Clients} />
            <ClientRoute path="/painel/clientes/:id" exact component={Client} />
            <ClientRoute
              path="/painel/cliente/novo"
              exact
              component={createClient}
            />

            <ClientRoute path="/painel/conta" exact component={BrokerAccount} />

            <ClientRoute
              path="/painel/playground"
              exact
              component={Playground}
            />

            {user && <ClientRoute exact component={NotFound} />}
          </Switch>
        ) : (
          <Switch>
            <ClientRoute path="/painel/" exact component={OwnerMainScreen} />
            <ClientRoute
              path="/painel/imoveis/cadastrar"
              exact
              component={CreatePropertyScreen}
            />

            <ClientRoute
              path="/painel/imoveis"
              exact
              component={PropertiesScreen}
            />

            <ClientRoute
              path="/painel/imoveis/pendentes"
              exact
              component={PendentsScreen}
            />

            <ClientRoute
              path="/painel/imoveis/:id"
              exact
              component={ShowPropertyScreen}
            />

            <ClientRoute
              path="/painel/configurações/"
              exact
              component={AccountScreen}
            />

            {user && <ClientRoute exact component={NotFound} />}
          </Switch>
        )}
      </>
    );
  }
}
