import styled from 'styled-components';

export const LocateContainer = styled.div`
  position: absolute;
  z-index: 12323;
  top: 48%;
  left: 93.8%;
  transform: translate(50%, 50%);
  button {
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); */
    width: 29px;
    height: 29px;
    font-size: 18px;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 0;
    margin: auto;
    transition: 0.18s ease-out;
    svg {
      font-size: 1rem;
    }
    &:hover {
      color: var(--app-terciary-color);
    }
    &:focus,
    &:active {
      color: #5896e4;
    }
  }
`;

export const LocateSearchContainer = styled.div`
  position: absolute;
  z-index: 12323;
  top: 63%;
  right: 1.1%;
  transform: translate(0%, 50%);
  button {
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); */
    width: 29px;
    height: 29px;
    font-size: 18px;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 0;
    margin: auto;
    transition: 0.18s ease-out;
    svg {
      font-size: 1rem;
    }
    &:hover {
      color: var(--app-terciary-color);
    }
    &:focus,
    &:active {
      color: #5896e4;
    }
  }
`;
