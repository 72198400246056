import { Rule } from 'antd/lib/form';

const name: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
  {
    type: 'string',
  },
];

const companyName: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const password: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
  {
    type: 'string',
    min: 6,
    message: 'A senha deve ter no mínimo seis caracteres',
  },
];

const email: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
  {
    type: 'email',
    message: 'Por favor insira um e-mail válido.',
  },
];

const phone: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const birthdate: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const maritalStatus: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const profession: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
  {
    type: 'string',
  },
];

const state: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

const creci: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const cpf: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const cnpj: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const zipcode: Rule[] = [
  {
    required: true,
    message: 'Campo Obrigatório.',
  },
  {
    type: 'string',
  },
];

const city: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

const neighborhood: Rule[] = [
  {
    required: true,
    message: ' Campo obrigatório.',
  },
];

const street: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const streetNumber: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const complement: Rule[] = [
  {
    required: false,
  },
];

const zone: Rule[] = [
  {
    required: true,
    message: 'Insira a zona do imóvel',
  },
];

const type: Rule[] = [
  {
    required: true,
    message: 'Por favor selecione o tipo do imóvel',
  },
];

const columns: Rule[] = [
  {
    required: true,
    message: 'Por favor seleciona a coluna',
  },
];

const acres: Rule[] = [
  {
    required: true,
    message: 'Por favor insira o número de hectáres do imóvel',
  },
];

const values: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

const images: Rule[] = [
  {
    required: true,
    message: 'Por favor insira as imagens do Imóvel',
  },
];

const contract: Rule[] = [
  {
    required: true,
    message: 'Você precisa aceitar os termos de uso.',
  },
];

const apartmentNumber: Rule[] = [
  {
    required: true,
    message: 'Por favor forneça o número do seu apartamento',
  },
];

const notRequiredCpf: Rule[] = [
  {
    required: false,
  },
];

const notRequiredEmail: Rule[] = [
  {
    required: false,
  },
  {
    type: 'email',
    message: 'Por favor, insira um e-mail válido.',
  },
];

const availability: Rule[] = [
  {
    required: true,
    message: 'Especifique a disponibilidade do imóvel',
  },
];

const decorator = {
  birthdate,
  columns,
  zipcode,
  city,
  companyName,
  complement,
  cpf,
  cnpj,
  creci,
  email,
  name,
  profession,
  maritalStatus,
  password,
  phone,
  state,
  neighborhood,
  street,
  streetNumber,
  zone,
  type,
  acres,
  values,
  images,
  contract,
  apartmentNumber,
  notRequiredCpf,
  notRequiredEmail,
  availability,
};

export default decorator;
