import gql from 'graphql-tag';
import {
  ICreatePropertyInput,
  IFilterPropertiesInput,
} from 'src/interfaces/property';
import client from './client.auth';

export const propertyServices = {
  fetchProperties: () =>
    client
      .query({
        query: gql`
          query properties {
            properties {
              id
              type
              stringCode
              subtype
              zone
              status
              address {
                street
                number
                neighborhood
                city
                state
                zipcode
                complement
                complex
                latitude
                longitude
              }
              builder
              images {
                path
              }
              area {
                backyard
                frontLot
                leftLot
                lotArea
                otherMeasures
                privateArea
                rightLot
              }
              caracteristics {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              values {
                annualIptu
                monthlyIptu
                communityRentPrice
                communitySalePrice
                rentPrice
                salePrice
                rentExtraTaxes
                saleExtraTaxes
              }
              columns
              isSold
            }
          }
        `,
      })
      .then((res) => res.data.properties),

  fetchFilteredProperties: (input: IFilterPropertiesInput) =>
    client
      .query({
        query: gql`
          query filterProperties($input: FilterPropertiesInput!) {
            filterProperties(input: $input) {
              id
              type
              stringCode
              subtype
              zone
              status
              address {
                street
                number
                neighborhood
                city
                state
                zipcode
                complement
                complex
                latitude
                longitude
              }
              builder
              images {
                path
              }
              area {
                backyard
                frontLot
                leftLot
                lotArea
                otherMeasures
                privateArea
                rightLot
              }
              caracteristics {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              values {
                annualIptu
                monthlyIptu
                communityRentPrice
                communitySalePrice
                rentPrice
                salePrice
                rentExtraTaxes
                saleExtraTaxes
              }
              columns
              isSold
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.filterProperties),

  fetchProperty: (id: string) =>
    client
      .query({
        query: gql`
          query property($id: ID!) {
            property(id: $id) {
              id
              type

              stringCode
              subtype
              zone
              status
              address {
                street
                number
                neighborhood
                city
                state
                zipcode
                complement
                complex
                latitude
                longitude
              }
              builder
              images {
                path
              }
              area {
                backyard
                frontLot
                leftLot
                lotArea
                otherMeasures
                privateArea
                rightLot
              }
              caracteristics {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              values {
                annualIptu
                monthlyIptu
                communityRentPrice
                communitySalePrice
                rentPrice
                salePrice
                rentExtraTaxes
                saleExtraTaxes
              }
              columns
              isSold
            }
          }
        `,
        variables: { id },
      })
      .then((res) => res.data.property),

  createProperty: (input: ICreatePropertyInput) =>
    client
      .mutate({
        mutation: gql`
          mutation createProperty($input: CreatePropertyInput!) {
            createProperty(input: $input) {
              id
              type
              subtype
              zone
              status
              address {
                street
                number
                neighborhood
                city
                state
                zipcode
                complement
                complex
                latitude
                longitude
              }
              builder
              images {
                path
              }
              car
              subscription
              itr
              scripture
              ccir
              registration
              bank
              overbalance
              extraHouses {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              area {
                backyard
                frontLot
                leftLot
                lotArea
                otherMeasures
                privateArea
                rightLot
              }
              caracteristics {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              values {
                annualIptu
                monthlyIptu
                communityRentPrice
                communitySalePrice
                rentPrice
                salePrice
                rentExtraTaxes
                saleExtraTaxes
              }
              columns
              isSold
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.createProperty),
};
