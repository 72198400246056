import { CLEAR_OWNER, FETCH_OWNER, IReducerOwnerState, OwnerActionTypes } from "./types";
import storage from 'redux-persist/es/storage';
import { persistReducer } from "redux-persist";

const initalState: IReducerOwnerState = {
  owner: undefined,
}

const persistConfig = {
  storage,
  key: 'owner',
  whitelist: ['owner'],
}

const reducer = (state = initalState, action: OwnerActionTypes) => {
  switch (action.type) {
    case FETCH_OWNER:
      return {
        ...state,
        owner: action.payload,
      }
    case CLEAR_OWNER:
      return {
        ...state,
        owner: undefined,
      }
    default:
      return state;
  }
}


export default persistReducer(persistConfig, reducer);