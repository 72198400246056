import styled from "styled-components";



export const GoBackButton = styled.p`
  display: flex;
  margin-top: 20px;
  font-size: 16px;
  transition: 0.2s all;
  a {
    display: flex;
    color: #4c48ff; 
    transition: 0.2s all;
    &:hover {
      color:  #4c48ff;
      opacity: 0.7;
    }
  }
  svg { 
    margin-right: 10px;
  }
  &:hover {
      color:  #4c48ff;
  }
`;