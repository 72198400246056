import gql from 'graphql-tag';
import client from './client.auth';

export const brokerServices = {
  broker: () =>
    client
      .query({
        query: gql`
          query broker {
            me {
              id
              cpf
              cnpj
              name
              companyName
              birthdate
              email
              phone
              broker {
                id
                address {
                  city
                  complement
                  complex
                  neighborhood
                  number
                  state
                  street
                  zipcode
                }
                creci
                status
                clients {
                  id
                  fullName
                  document
                  cnpj
                  email
                  phone
                }
                appointments {
                  id
                  appointmentDate
                  appointmentHour
                  property {
                    id
                  }
                }
              }
            }
          }
        `,
      })
      .then((res) => res.data.me),
};
