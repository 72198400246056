import { Dispatch } from "react";
import { OwnerServices } from "src/services/owner";
import { FETCH_OWNER, OwnerActionTypes } from "./types";

export const fetchOwner = () => (dispatch: Dispatch<OwnerActionTypes>) => {
  return OwnerServices.owner().then(res => {
    dispatch({
      type: FETCH_OWNER,
      payload: res,
    })
    return res;
  }).catch(() => {
    throw Error;
  })
}