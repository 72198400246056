import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { FETCH_SCHEDULES, IReducerScheduleState, ScheduleActionTypes } from './types';



const initialState: IReducerScheduleState = {
  schedules: [],
}


const persistConfig = {
  storage,
  key: 'schedules',
  whitelist: ['schedules']
}


const reducer = (state = initialState, action: ScheduleActionTypes) => {
  switch (action.type) {
    case FETCH_SCHEDULES:
      return {
        ...state,
        schedules: action.payload,
      }
      default: 
        return state;
  }
}

export default persistReducer(persistConfig, reducer);