import { notification, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'src/components';

import { IReduxState } from 'src/store';
import { fetchOwner } from 'src/store/owner/actions';
import { table } from 'src/utils';

import { IParams, IProps } from './types/properties.types';
import TableActions from 'src/components/tableActions/tableActions.component';

const PropertiesScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const { owner } = props;
  const properties = owner && owner.owner.properties;

  const approvedProperties = properties?.filter(
    (property) => property.status === 'approved'
  );

  useEffect(() => {
    const id = (props.match.params as IParams).id;

    props.fetchOwner(id).catch(() => {
      return notification.error({
        message:
          'Ocorreu um erro ao atualizar os imóveis, por favor, tente novamente mais tarde.',
      });
    });
    setLoading(false);
  }, []);
  const dataSource = approvedProperties?.map((property) => ({
    key: property.id,
    salePrice:
      property.values.salePrice?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) || 'Não Informado.',
    rentPrice:
      property.values.rentPrice?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) || 'Não Informado.',
    type: property.type,
    subtype: property.subtype,
    zone: property.zone,
  }));

  const columns = [
    {
      title: 'Preço de Venda',
      key: 'salePrice',
      dataIndex: 'salePrice',
    },
    {
      title: 'Preço de Locação',
      key: 'rentPrice',
      dataIndex: 'rentPrice',
    },
    {
      title: 'Zona',
      key: 'zone',
      dataIndex: 'zone',
    },
    {
      title: 'Tipo',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Subtípo',
      key: 'subtype',
      dataIndex: 'subtype',
    },
    {
      title: 'Ações',
      key: 'actions',
      dataIndex: 'actions',
      render: (text: string, record: any) => (
        <>
          <TableActions
            view={{ link: `/painel/imoveis/${record.key}` }}
            loading={loading}
          />
        </>
      ),
    },
  ];

  return (
    <Card
      title="Imóveis"
      subtitle="Veja aqui seus imóveis aprovados no sistema."
    >
      <Table
        scroll={{ x: 500 }}
        dataSource={dataSource}
        {...table}
        columns={columns}
      />
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  owner: state.owner.owner,
});

export default connect(mapStateToProps, { fetchOwner })(PropertiesScreen);
