import { IUser } from 'src/interfaces/user';

export const SIGN_IN_OWNER = "SIGN_IN_OWNER";
export const FETCH_DATA = 'FETCH_DATA';
export const SIGN_IN_BROKER = 'SIGN_IN_BROKER';
export const SIGNUP_BROKER = 'SIGNUP_BROKER';
export const SIGNUP_OWNER = 'SIGNUP_OWNER';

export const LOGOUT_USER = 'LOGOUT_USER';

export interface IReducerAuthState {
  user?: IUser;
}

export interface ISignInOwner {
  type: typeof SIGN_IN_OWNER;
  payload: IUser;
}

export interface IFetchData {
  type: typeof FETCH_DATA;
}

export interface ISignInBroker {
  type: typeof SIGN_IN_BROKER;
  payload: IUser;
}

export interface ISignupBroker {
  type: typeof SIGNUP_BROKER;
  payload: IUser;
}

export interface ISignupOwner {
  type: typeof SIGNUP_OWNER;
  payload: IUser;
}

export interface ILogout {
  type: typeof LOGOUT_USER;
}


export type AuthActionTypes = ISignInOwner
  | ISignInBroker
  | IFetchData
  | ISignupBroker
  | ISignupOwner
  | ILogout;
