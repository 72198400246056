import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { brokerLogin } from 'src/store/auth/actions';
import { Container, Wrapper } from './styles/broker.styles';
import { IProps } from './types/broker';
import { decorator } from 'src/utils';

const BrokerAuthScreen: React.FC<IProps> = (props) => {
  const [sending, setSending] = useState(false);

  const onFinish = (values: any) => {
    setSending(true);
    return props.brokerLogin(values).catch(() => {
      setSending(false);
    });
  };

  return (
    <Wrapper>
      <Container>
        <h1> Corretor(a)</h1>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item label="E-mail" name="email" rules={decorator.email}>
            <Input size="large" placeholder="Insira o e-mail..." />
          </Form.Item>
          <Form.Item label="Senha" name="password" rules={decorator.password}>
            <Input.Password size="large" placeholder="Insira a senha..." />
          </Form.Item>
          <Row justify="center">
            <Button htmlType="submit" className="btn-success" loading={sending}>
              Entrar
            </Button>
          </Row>
        </Form>
        <br />
        <Row justify="center">
          <Link to="/corretor/cadastrar">Não tem uma conta? Cadastre-se!</Link>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default connect(undefined, { brokerLogin })(BrokerAuthScreen);
