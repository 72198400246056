import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'src/components';

import { IAppointment } from 'src/interfaces/appointment';
import { fetchAppointments } from 'src/store/appointments/actions';
import { IReduxState, store } from 'src/store';
import { Button, Spin, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { table } from 'src/utils';
import { pt } from 'date-fns/locale';
import TableActions from 'src/components/tableActions/tableActions.component';
import { Redirect } from 'react-router-dom';

interface IProps {
  appointments?: IAppointment[];
  fetchAppointments: () => Promise<IAppointment[]>;
}

const AppointmentsScreen: React.FC<IProps> = (props) => {
  const { appointments } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [redirect, setRedirect] = useState<string>('');
  const [query, setQuery] = useState<boolean>(false);

  const antIcon = <LoadingOutlined spin />;
  useEffect(() => {
    props
      .fetchAppointments()
      .then(() => {
        setLoading(false);
        if (store.getState().broker.broker?.broker.status !== 'approved') {
          setRedirect('/painel/conta/');
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const dataSource =
    appointments &&
    appointments.map((appointment) => ({
      key: appointment.id,
      date: format(
        new Date(appointment.appointmentDate),
        "'Dia' dd 'de' MMMM",
        { locale: pt }
      ),
      hour: appointment.appointmentHour.substring(
        0,
        appointment.appointmentHour.length - 3
      ),
      propertyType: appointment?.property?.type,
      address: appointment.property
        ? `${appointment.property.address.street}
      ${appointment.property.address.number}, 
      ${appointment.property.address.neighborhood}`
        : '',
    }));

  const columns = [
    {
      title: 'Data do Agendamento',
      key: 'date',
      dataIndex: 'date',
    },
    {
      title: 'Hora do Agendamento',
      key: 'hour',
      dataIndex: 'hour',
    },
    {
      title: 'Endereço',
      key: 'address',
      dataIndex: 'address',
    },

    {
      title: 'Tipo do Imóvel',
      key: 'propertyType',
      dataIndex: 'propertyType',
    },
    {
      title: ' Ações',
      key: 'actions',
      dataIndex: 'actions',
      render: (text: string, record: any) => (
        <>
          <TableActions
            view={{ link: `/painel/agendamentos/` }}
            loading={loading}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <Card title="Agendamentos" subtitle="Aqui estão os agendamentos feitos">
        <Table
          scroll={{ x: 500 }}
          dataSource={dataSource}
          {...table}
          columns={columns}
          loading={{ spinning: loading, indicator: antIcon }}
        />
      </Card>
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  appointments: state.appointments.appointments,
});

export default connect(mapStateToProps, { fetchAppointments })(
  AppointmentsScreen
);
