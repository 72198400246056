import { Form, notification, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useState } from 'react';

import GoBack from 'src/components/GoBack/GoBack';
import { CreateWrapper, CreateContainer } from './brokerSignup.styles';
import { signUpBroker } from 'src/store/auth/actions';

import { IProps } from './brokerSignup.types';
import PersonForm from './personForm.component';
import CompanyForm from './companyForm.component';
import { connect } from 'react-redux';

const BrokerSignupScreen: React.FC<IProps> = (props) => {
  const [checked, setChecked] = useState('');
  const [loading, setLoading] = useState(false);

  // tslint:disable-next-line: no-shadowed-variable
  const { signUpBroker } = props;

  const onFinish = (values: any) => {
    setLoading(true);
    signUpBroker(values).then((res) => {
      notification.success({
        message: 'Cadastro efetuado com sucesso!',
      });
    });
  };

  const onChange = (e: RadioChangeEvent) => {
    setChecked(e.target.value);
  };

  return (
    <>
      <CreateWrapper>
        <GoBack />
        <h1>Seja bem-vindo!</h1>
        <h2>
          Escolha o tipo de Pessoa (Jurídica ou Fisíca) e preencha o formulário
          para prosseguir.
        </h2>
        <CreateContainer>
          <Row justify="center" style={{ padding: '10px 0 25px 0' }}>
            <Radio.Group onChange={onChange} value={checked}>
              <Radio value="person" checked={checked === 'person'}>
                Pessoa Física
              </Radio>
              <Radio value="company" checked={checked === 'company'}>
                Pessoa Jurídica
              </Radio>
            </Radio.Group>
          </Row>
          <Form onFinish={onFinish} layout="vertical">
            {checked === 'person' ? <PersonForm sending={loading} /> : null}
            {checked === 'company' ? <CompanyForm sending={loading} /> : null}
          </Form>
        </CreateContainer>
      </CreateWrapper>
    </>
  );
};

export default connect(undefined, { signUpBroker })(BrokerSignupScreen);
