import {
  CAR,
  CCIR,
  CREATE_PROPERTY,
  FETCH_PROPERTIES,
  FETCH_PROPERTY,
  CLEAR_PROPERTIES,
  IMAGES,
  IReducerPropertyState,
  ITR,
  PropertyActionTypes,
  REGISTRATION,
  SCRIPTURE,
  STORE_AREA,
  STORE_VALUES,
  SUBSCRIPTION,
  MARKED_PROPERTIES,
  REMOVE_MARKED_PROPERTIES,
  CLEAR_MARKED_PROPERTIES,
  GET_PDF,
  REMOVE_PDF,
  CLEAR_PDFS,
} from './types';
import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';

const initialState: IReducerPropertyState = {
  property: undefined,
  properties: [],
  markedProperties: [],
  propertiesPdf: [],
};

const persistConfig = {
  storage,
  key: 'properties',
  whitelist: ['properties'],
  blacklist: ['propertiesPdf'],
};

const reducer = (state = initialState, action: PropertyActionTypes) => {
  switch (action.type) {
    case FETCH_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
      };

    case CLEAR_PROPERTIES:
      return {
        ...state,
        properties: undefined,
      };
    case FETCH_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };
    case CREATE_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };

    case MARKED_PROPERTIES:
      return {
        ...state,
        markedProperties: [...state?.markedProperties!, action.payload],
      };

    case REMOVE_MARKED_PROPERTIES:
      const removedOne = state.markedProperties?.filter(
        (marked) => marked.id !== action.payload.id
      );

      return {
        ...state,
        markedProperties: removedOne,
      };
    case GET_PDF:
      return {
        ...state,
        propertiesPdf: state?.propertiesPdf?.concat([action.payload]),
      };

    case REMOVE_PDF:
      const removedPdf = state.propertiesPdf?.filter(
        (pdf) => pdf !== action.payload
      );
      return {
        ...state,
        propertiesPdf: removedPdf,
      };

    case CLEAR_PDFS:
      return {
        ...state,
        propertiesPdf: [],
      };
    case CLEAR_MARKED_PROPERTIES:
      return {
        ...state,
        markedProperties: [],
      };

    case STORE_VALUES:
      return {
        ...state,
        values: action.payload,
      };

    case STORE_AREA:
      return {
        ...state,
        area: action.payload,
      };
    case SCRIPTURE:
      return {
        ...state,
        scripture: action.payload,
      };
    case REGISTRATION:
      return {
        ...state,
        registration: action.payload,
      };
    case SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case CAR:
      return {
        ...state,
        car: action.payload,
      };
    case CCIR:
      return {
        ...state,
        ccir: action.payload,
      };
    case ITR:
      return {
        ...state,
        itr: action.payload,
      };

    case IMAGES:
      return {
        ...state,
        images: action.payload,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducer);
