import { Col, Divider, Form, Input, InputNumber, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { IArea } from 'src/interfaces/property';
import { store } from 'src/store';
import { STORE_AREA } from 'src/store/property/types';

const AreaForm: React.FC = () => {
  const { Item } = Form;

  const [privateArea, setPrivateArea] = useState<number | undefined>();
  const [lotArea, setLotArea] = useState<number | undefined>();
  const [frontLot, setFrontLot] = useState<number | undefined>();
  const [rightLot, setRightLot] = useState<number | undefined>();
  const [leftLot, setLeftLot] = useState<number | undefined>();
  const [backyard, setBackyard] = useState<number | undefined>();

  const area: IArea = {
    privateArea,
    lotArea,
    frontLot,
    rightLot,
    leftLot,
    backyard,
  };

  useEffect(() => {
    store.dispatch({
      type: STORE_AREA,
      payload: area,
    });
  }, [area]);

  return (
    <>
      <Divider />
      <h3>Área do Imóvel</h3>
      <Row gutter={16}>
        <Col md={8} lg={8} sm={12} xs={24}>
          <Item label="Área Privativa" name={['area', 'privateArea']}>
            <NumberFormat
              thousandSeparator={'.'}
              decimalSeparator={','}
              suffix={' m²'}
              onValueChange={(value) => setPrivateArea(value.floatValue)}
              decimalScale={2}
              customInput={Input}
            />
          </Item>
        </Col>
        <Col md={8} lg={8} sm={12} xs={24}>
          <Item label="Área do Lote" name={['area', 'lotArea']}>
            <NumberFormat
              thousandSeparator={'.'}
              decimalSeparator={','}
              suffix={' m²'}
              onValueChange={(value) => setLotArea(value.floatValue)}
              decimalScale={2}
              customInput={Input}
            />
          </Item>
        </Col>
        <Col md={8} lg={8} sm={12} xs={24}>
          <Item label="Frente" name={['area', 'frontLot']}>
            <NumberFormat
              thousandSeparator={'.'}
              decimalSeparator={','}
              suffix={' m²'}
              onValueChange={(value) => setFrontLot(value.floatValue)}
              decimalScale={2}
              customInput={Input}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={8} lg={8} sm={12} xs={24}>
          <Item label="Lote direito" name={['area', 'rightLot']}>
            <NumberFormat
              thousandSeparator={'.'}
              decimalSeparator={','}
              suffix={' m²'}
              onValueChange={(value) => setRightLot(value.floatValue)}
              decimalScale={2}
              customInput={Input}
            />
          </Item>
        </Col>
        <Col md={8} lg={8} sm={12} xs={24}>
          <Item label="Lote esquerdo" name={['area', 'leftLot']}>
            <NumberFormat
              thousandSeparator={'.'}
              decimalSeparator={','}
              suffix={' m²'}
              onValueChange={(value) => setLeftLot(value.floatValue)}
              decimalScale={2}
              customInput={Input}
            />
          </Item>
        </Col>
        <Col md={8} lg={8} sm={12} xs={24}>
          <Item label="Fundos" name={['area', 'backyard']}>
            <NumberFormat
              thousandSeparator={'.'}
              decimalSeparator={','}
              suffix={' m²'}
              onValueChange={(value) => setBackyard(value.floatValue)}
              decimalScale={2}
              customInput={Input}
            />
          </Item>
        </Col>
      </Row>
    </>
  );
};

export default AreaForm;
