import {
  ClientActionTypes,
  CREATE_CLIENT,
  FETCH_CLIENT,
  FETCH_CLIENTS,
  CLEAR_CLIENT,
  IReducerClientState,
  SEND_PDFS,
} from './types';
import storage from 'redux-persist/es/storage';
import persistReducer from 'redux-persist/es/persistReducer';

const initialState: IReducerClientState = {
  client: undefined,
  clients: [],
};

const persistConfig = {
  storage,
  key: 'client',
  whitelist: ['client'],
};

const reducer = (state = initialState, action: ClientActionTypes) => {
  switch (action.type) {
    case FETCH_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case FETCH_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };
    case CREATE_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case CLEAR_CLIENT:
      return {
        ...state,
        client: undefined,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducer);
