import React from 'react';
import { Container, Icon, Descriptions } from './caracteristics.styles.';

interface IProps {
  description: string;
  amount?: number;
  icon?: object;
}

const Caracteristics: React.FC<IProps> = (props) => {
  const { description, amount, icon } = props;
  return (
    <Container>
      <Icon>
        <span>{icon}</span>
      </Icon>
      <p>
        {amount} {amount && amount > 1 ? description.concat('s') : description}
      </p>
    </Container>
  );
};

export default Caracteristics;
