import { IUser } from "src/interfaces/user";

export const FETCH_BROKER = 'FETCH_BROKER';
export const CLEAR_BROKER = 'CLEAR_BROKER';





export interface IReducerBrokerState {
  broker?: IUser;
} 

export interface IFetchBroker {
  type: typeof FETCH_BROKER;
  payload: IUser;
}

export interface IClearBroker {
  type: typeof CLEAR_BROKER;
}



export type BrokerActionTypes = IFetchBroker | IClearBroker;
