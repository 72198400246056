import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { GoBack } from 'src/components';

import { IReduxState } from 'src/store';
import { fetchProperty } from 'src/store/property/actions';
import Data from './components/showProperty/data.component';

import { IProps, IParams } from './types/show.property.types';

const ShowPropertyScreen: React.FC<IProps> = (props) => {
  const { property } = props;

  useEffect(() => {
    const id = (props.match.params as IParams).id;
    props.fetchProperty(id);
  }, []);

  return (
    <>
      <GoBack />
      {!!property && <Data property={property} />}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  property: state.properties.property,
});

export default connect(mapStateToProps, { fetchProperty })(ShowPropertyScreen);
