import styled from 'styled-components';


export const LogoTitle = styled.div`
  width: 100%;
  display: flex;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
  img {
    width: 120px;

  }
`;

export const DrawerBody = styled.div`
  width: 100%;
  
`;