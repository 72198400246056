import { Descriptions, Table } from 'antd';
import React from 'react';
import { Card } from 'src/components';
import { IUser } from 'src/interfaces/user';
import { maritalStatus } from 'src/utils';

export interface IProps {
  owner: IUser;
}

const OwnerData: React.FC<IProps> = (props) => {
  const { owner } = props;
  const { Item } = Descriptions;

  return (
    <>
      <Card
        title="Minhas Informações"
        subtitle="Veja aqui as suas informações no sistema."
      >
        <Descriptions title={owner.name}>
          {owner.name ? (
            <>
              <Item label="CPF"> {owner.cpf} </Item>
              <Item label="Estado Civíl">
                {
                  maritalStatus.find(
                    (item) => item.value === owner.owner?.maritalStatus
                  )?.name
                }
              </Item>
              <Item label="">{''}</Item>
              <Item label="Inscrição Estadual">
                {owner.owner?.stateRegistration || '-'}
              </Item>

              <Item label="Data de nascimento"> {owner.birthdate} </Item>
            </>
          ) : (
            <>
              <Item label="Nome da empresa"> {owner.companyName} </Item>
              <Item label="CNPJ"> {owner.cnpj} </Item>
              <Item label="Inscrição Estadual">
                {owner.owner?.stateRegistration || 'Não Informado.'}
              </Item>
            </>
          )}
        </Descriptions>
        <Descriptions title="Informações de Contato">
          <Item label="E-mail"> {owner.email} </Item>
          <Item label="Telefone"> {owner.phone} </Item>
        </Descriptions>
        <Descriptions title="Endereço">
          <Item label="Rua">{owner.owner?.address.street} </Item>
          <Item label="Número">{owner.owner?.address.number}</Item>
          <Item label="Bairro">{owner.owner?.address.neighborhood}</Item>
          <Item label="Complemento">
            {owner.owner?.address.complement || 'Não Informado.'}
          </Item>
          <Item label="Cidade">{owner.owner?.address.city}</Item>
          <Item label="Estado">{owner.owner?.address.state}</Item>
          <Item label="CEP">{owner.owner?.address.zipcode}</Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default OwnerData;
