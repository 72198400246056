import React from 'react';

import { Container, Title } from './contract.styles';

const Contract: React.FC = () => {
  return (
    <>
      <Title>Termos de uso e Política de Privacidade da EMBRANE</Title>
      <Container>
        <p>
          Está política descreve as formas de como coletamos, armazenamos,
          usamos e protegemos suas informações pessoais. Você aceita essa
          política e concorda com tal coleta, armazenamento e uso quando se
          inscrever ou utilizar nossos produtos, serviços ou qualquer outro
          recurso, tecnologia ou funcionalidade que nós oferecemos ao acessar
          nosso site ou por qualquer outro meio{' '}
          <b>(coletivamente os serviços EMBRANE).</b> Podemos alterar essa
          política a qualquer momento, divulgando uma versão revisada em nosso
          site. A versão revisada entrará em vigor assim que disponibilizada no
          site. Além disso, se a versão revisada incluir uma alteração
          substancial, avisaremos com 30 dias de antecedência, divulgando o
          aviso através de um comunicado enviado a todos os usuários do sistema.
          Depois desse aviso de 30 dias, será considerado que você concorda com
          todas as emendas feitas a essa política.
        </p>

        <h3>Importante: Política de Descadastramento (Opt-out)</h3>
        <p>
          O usuário dos nossos servíços pode a qualquer momento deixar de
          receber comunicações e de utilizar dos serviços do nosso site. Para
          tanto basta enviar um e-mail para{' '}
          <a style={{ color: 'green' }}>contato@embrane.com.br</a> indicando seu
          desejo de não mais receber informações, ou clicar no link "remover" ou
          "desinscrever" contido no final de cada e-mail.
        </p>
        <h3>Como coletamos informações a seu respeito</h3>
        <p>
          Quando você visita o site da <b>EMBRANE</b> ou utiliza de seus
          serviços, coletamos seu endereço de IP e as informações padrões de
          acesso à web como o tipo do seu navegador e as páginas que acessou em
          nosso site. Se você se inscrever, coletaremos os seguintes tipos de
          informações:{' '}
        </p>
        <ul>
          <li>
            Informações de contato - seu nome, endereço, telefone, e-mail e
            outras informações semelhantes.
          </li>
        </ul>
        <p>
          Antes de permitir o uso dos <b>Serviços da EMBRANE</b>, poderemos
          exigir que você forneça informações adicionais que poderemos utilizar
          para verificar sua identidade, endereço ou gerenciar risco, como sua
          data de nascimento, número do CRECI da regial da qual estará
          cadastrando, número do registro nacional, nacionalidade entre outras
          informações de identificação. Também poderemos obter informações suas
          através de terceiros, como centros de crédito e serviços de
          verificação de indentidade. Quando estiver utilizando os{' '}
          <b>Serviços da EMBRANE</b>, coletaremos informações sobre suas
          transações e outras atividades em nosso site ou quando utilizar de
          nosso serviços e podemos coletar informações sobre seu computador ou
          outro dispositivo de acesso com a finalidade de prevenção contra
          fraude. Por fim, podemos coletar informações adicionais de e sobre
          você por outros meios, como de contatos com nossa equipe de suporte ao
          cliente, de resultados de suas respostas a uma pesquisa, de interações
          com membros da equipe EMBRANE, e de outras empresas.
        </p>
        <p>
          <b>Como Usamos cookies.</b> Quando você acessa nosso site, nós,
          incluindo as empresas que contratamos para acompanhar como nosso site
          é usado, podemos colocar pequenos arquivos de dados chamados{' '}
          <b>cookie</b> no seu computador. Enviamos um <b>cookie de sessão</b>{' '}
          para o seu computador quando você entra em sua conta ou usa os{' '}
          <b>Serviços EMBRANE.</b> Esse tipo de cookie nos ajuda a reconhecê-lo
          se visitar várias páginas em nosso site durante a mesma sessão, para
          que não precisemos solicitar a sua senha em todas as páginas. Depois
          que você sair ou fechar seu navegador, este cookie irá expirar deixará
          de ter efeito. Também usamos cookies permanentes para outras
          funcionalidades, como por exemplo para exibir o seu endereço de e-mail
          em nosso formulário de acesso, para que você não precise digitar
          novamente o endereço de e-mail sempre que entrar em sua conta.
          Codificamos nossos cookies para que apenas nós possamos interpretar as
          informações armazenadas neles. Você está livre para recusar nossos
          cookies caso seu navegador permita, mas isso pode no uso de nosso
          site. Nós e nossos prestadores de serviço também usamos cookies para
          personalizar nosso serviços, conteúdo e publicidade, avaliar a
          eficiência das promoções e promover confiança e segurança. Você pode
          encontrar cookies de terceiros ao usar os serviços da EMBRANE em
          determinados sites que não estão sob nosso controle (por exemplo, se
          você visualizar uma página da Web criada por terceiros ou usar um
          aplicativo desenvolvido por terceiros, um cookie poderá ser colocado
          por essa página ou aplicativo).
        </p>
        <p>
          <b>Remarketing do Google.</b> Nós utilizamos o recurso de Remarketing
          do Google Ads para veicular anúncios em sites parceiros do Google.
          Este recurso permite identificar que você visitou o nosso site e assim
          o Google pode exibir nosso anúncio para você em diferentes websites.
          Diversos fornecedores de terceiros, inclusive o Google, compram
          espaços de publicidade sites da Internet, Nós eventualmente
          contratamos o Google para exibir nossos anúncios nesses espaços. Para
          identificar a sua visita ao nosso site, tanto outros fornecedores de
          terceiros, quanto o Google, utilizam-se de cookies, de forma similar
          ao exposto na seção{' '}
          <b>
            <i>Como utilizamos cookies.</i>
          </b>{' '}
          Você pode desativar o uso cookies pelo Google acessando o{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://adssettings.google.com/authenticated?hl=pt-BR"
          >
            Gerenciador de preferências de anúncio.
          </a>
        </p>
        <h3>Como protegemos e armazenamos informações pessoais</h3>
        <p>
          Ao longo desta política, usamos o termo "informações pessoais" para
          descrever informações que possam ser associadas a uma determinada
          pessoa e possam ser utilizadas para identificar essa pessoa. Nós não
          consideraremos como informações pessoais aquelas que devem permanecer
          anônimas, para que elas não identifiquem um determinado usuário.
          Armazenamos e processamos suas informações pessoais em nossos
          computadores e as protegemos sob medida de proteções físicas,
          eletrônicas e processuais. Usamos proteção de computador, como
          firewalls e criptografia de dados, aplicamos controles de acesso
          físico nossos prédios e arquivos e autorizamos o acesso a informações
          pessoais apenas para os funcionários que precisem delas para cumprir
          suas responsabilidades profissionais.
        </p>
        <h3>Como usamos as informações pessoais que coletamos</h3>
        <p>
          Nossa finalidade principal ao coletar informações pessoais é fornecer
          a você uma experiência segura, tranquila, eficiente e personalizada.
          Para isso, usamos suas informações pessoais para:
        </p>
        <ul>
          <li>Fornecer serviços e suporte solicitados pelo cliente.</li>
          <li>
            Processar transações e enviar avisos sobre as suas transações.
          </li>
          <li>Solucionar disputas, cobrar taxas e solucionar problemas.</li>
          <li>
            Impedir atividades potencialmente proibidas ou ilegais e garantir a
            aplicação do nosso Contrato do Usuário.
          </li>
          <li>
            Enviar materiais de marketing direcionados, avisos de atualização no
            serviço e ofertas promocionais com base nas suas preferências de
            comunicação.
          </li>
          <li>
            Comparar informações, para uma maior precisão, e verificá-las com
            terceiros.
          </li>
        </ul>
        <h3>Marketing</h3>
        <p>
          Se não desejar receber nossas mensagens de marketing nem participar de
          nossos programas de personalização de anúncios, basta indicar sua
          preferência nos mandando um e-mail ou simplesmente clicar no link de
          descadastramento fornecido em todos os nossos e-mails.
        </p>
        <h3>Como compartilhamos informações pessoais com outras fontes</h3>
        <p>Podemos compartilhar suas informações pessoais com:</p>
        <ul>
          <li>
            <b>Membros da equipe EMBRANE</b> para fornecer conteúdo, produtos e
            serviços conjunto (como registros, transações e suporte ao cliente)
            para ajudar a detectar e impedir atos potencialmente ilegais e
            violações de nossas políticas, além de cooperar nas decisões quanto
            a seus produtos, serviços e comunicações. Os membros da nossa equipe
            corporativa usarão essas informações para lhe enviar comunicações de
            marketing apenas se você tiver solicitado seus serviços.
          </li>
          <li>
            <b>
              Fornecedores de serviços sob contrato que colaboram em parte de
              nossas operações comerciais.
            </b>
            (prevenção contra fraude, atividades de cobrança, marketing,
            serviços de tecnologia). Nossos contratos determinam que esses
            fornecedores de serviço só usem suas informações em relação aos
            serviços que realizam para nós, e não em benefício próprio.
          </li>
          <li>
            <b>Empresas com as quais pretendemos nos fundir ou adquirir.</b> (Se
            ocorrer uma fusão, exigiremos que a nova entidade constituída siga
            essa política de privacidade com relação às suas informações
            pessoais. Se suas informações pessoais puderem ser usadas contra
            essa política, você receberá um aviso prévio).
          </li>
          <li>
            <b>
              Autoridades policiais, oficiais do governo ou outros terceiros
              quando:
            </b>
            <ul>
              <li>
                formos obrigados a isso por intimação, decisão judicial, ou
                procedimento legal semelhante;
              </li>
              <li>
                precisarmos fazer isso para estar em conformidade com a lei ou
                com as regras de associação de cartão de crédito;
              </li>
              <li>
                estivermos cooperando com uma investigação policial em
                andamento;
              </li>
              <li>
                acreditarmos, de boa fé, que a divulgação das informações
                pessoais é necessária para impedir danos físicos ou perdas
                financeiras, para reportar atividade ilegal suspeita ou
                investigar violações do nosso <b>Contrato de Usuário;</b>
              </li>
            </ul>
          </li>
          <li>
            <b>
              Outros terceiros com seu consentimento ou orientação para tanto.
            </b>
          </li>
        </ul>
        <p>
          Note que esses terceiros podem estar em outros países nos quais a
          legislação sobre o processamento de informações pessoais seja menos
          rígida do que a do seu país. Nos casos em que nossa empresa e um
          parceiro promovem juntos os nossos serviços, nós podemos divulgar ao
          parceiro algumas informações pessoais tais como nome, endereço e nome
          de usuário das pessoas que se inscreveram nos nossos serviços como
          resultado da promoção conjunta com o único propósito de permitir a nós
          e ao parceiro avaliar os resultados da promoção. Nesse caso,
          informações pessoais não podem ser utilizadas pelo parceiro para
          nenhum outro propósito. A{' '}
          <b>
            EMBRANE não venderá ou alugará nenhuma de suas informações pessoais
            para terceiros
          </b>{' '}
          no curso normal dos negócios e só compartilhará suas informações
          pessoais com terceiros conforme descrito nesta política. Se você se
          inscrever na EMBRANE diretamente em um site de terceiros ou por meio
          de um aplicativo de terceiros, qualquer informação inserida naquele
          site ou aplicativo (e não diretamente em um site da EMBRANE) será
          compartilhada com o proprietário deste site ou aplicativo - e suas
          informações podem estar sujeitas a política de privacidade deles.
        </p>
        <h3>Comprometimento e Adesão</h3>
        <p>
          O usuário deverá ser inscrito pelo{' '}
          <b>CRECI (Conselho Regional dos Corretores de Imóveis)</b> do estado
          qual está tem suas atividades e preencher o formulário de registro
          completo que deverá ser analisado pela EMBRANE e terá a resposta de
          cadastro em doze horas após o envio do formulário. Tendo seu cadastro
          positivo em consonância com o CRECI. receberá login e senha para
          acesso que poderão ser modificados caso precise. O usuário Corretor
          (Pessoa Física) ou Imobiliária (Pessoa Jurídica) admitirá fidelidade
          nas seguintes propostas abaixo:
          <ul>
            <li>
              Receberá os anúncios de imóveis com{' '}
              <b>
                <i>
                  valores de comissionamento pré-fixados em 1% (um por cento).
                </i>
              </b>{' '}
              O qual deverá ser pago a EMBRANE pelo proprietário, após receber o
              sinal de negócio ou conforme qualquer circunstância combinada, não
              podendo questionar quanto a prestação de serviços e suas
              remunerações pré-fixadas.
            </li>
            <li>
              O corretor não terá incidência de despesas de comissionamento com
              a EMBRANE uma vez já acordado com o <b>PROPRIETÁRIO</b> do imóvel
              divulgado.
            </li>
            <li>
              Em caso de visitas o corretor será monitorado com relatórios,
              agendado a visita e não comparecimento o mesmo será penalizado
              caso não avise em tempo hábil. caso tenha 10 (dez) registros
              negativos{' '}
              <i>
                (os registros ocorrem em função de visitas agendadas e não
                comparecimento do CORRETOR e ou CLIENTE)
              </i>{' '}
              o CORRETOR terá seu login bloqueado, pagando uma multa de 10 (dez)
              salários mínimos ele poderá reativar seu cadastro, não acontecendo
              o pagamento no prazo de 24 (vinte e quatro) horas, seu login será
              cancelado definitivamente. Outro motivo qual o USUÁRIO CORRETOR
              terá seu login cancelado imediatamente sem consentimento de multas{' '}
              <i>
                {' '}
                (caso haja negociação de imóveis ora divulgados dentro da
                plataforma feita por fora de nossos conhecimentos).
              </i>{' '}
              As visitas não poderão ser canceladas quando efetivadas, tenha
              bastante atenção.
            </li>
            <li>
              As visitas terão tempo pré-determinado a partir do agendamento até
              o nosso <b>COLABORADOR EMBRANE</b> chegar até o local o qual fará
              o relatório individual por visita.
            </li>
            <li>
              Os imóveis comprometidos em visitas terão temporizador para novos
              agendamentos assim otimizando as demandas.
            </li>
            <li>
              As propostas deverão ser comunicadas a EMBRANE{' '}
              <i>(sem exceção)</i> a qual fará contato com o PROPRIETÁRIO &
              CORRETOR conciliando o negócio da melhor forma.
            </li>
          </ul>
        </p>
      </Container>
    </>
  );
};

export default Contract;
