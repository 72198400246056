import styled from 'styled-components';

export const FilterContainer = styled.div`
  height: auto;
  background: var(--app-secondary-color);
  padding: 0 1rem;
  position: relative;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    background: var(--app-quarternary-color);
    color: var(--app-secondary-color);
    border-radius: 20px;
    border: none;
    padding: 0 16px;
    margin-left: 10px;
    &:hover,
    &:active,
    &:focus {
      background: #0065d4;
      opacity: 1;
    }
  }
`;

export const MapContainer = styled.div`
  position: fixed;
  z-index: 124124213;
  height: 100%;
  width: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.5s;
  overflow-x: hidden;
  background: var(--app-terciary-color);

  .closeButton {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 50%);
    color: rgba(0, 0, 0, 1);
    background: transparent;
    border: none;
    transition: filter 0.3s;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    svg {
      color: #fff;
      width: 100%;
      height: 100%;
    }
    &:hover,
    &:focus {
      outline: none;
    }
  }
  @media (max-height: 450px) {
    top: 15px;
    right: 35px;
  }
`;

export const FilterFormFooter = styled.div`
  padding: 1rem 0.5rem 0 0.5rem;
  position: sticky;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const PropertyContainer = styled.div`
  min-height: 410px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.5rem;
  color: var(--text-color);
  line-height: 1rem;
  letter-spacing: -0.2px;
  margin: 4rem auto auto auto;
`;

export const TitleContainer = styled.div`
  h2 {
    letter-spacing: -0.2px;
    color: var(--primary-blue-dark);
    line-height: 1.5rem;
    font-family: Orbitron, sans-serif;
    font-weight: 400;
    font-size: 2rem;
  }
  p {
    letter-spacing: -0.2px;
    line-height: 1.5rem;
    font-weight: 400;
  }
`;
