import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import { connect } from 'react-redux';
import { addDays, format } from 'date-fns';
import { pt } from 'date-fns/locale';

import { IReduxState, store } from 'src/store';
import { ISchedule } from 'src/interfaces/schedule';
import { fetchSchedules } from 'src/store/schedule/actions';
import {
  fetchAppointments,
  createAppointment,
} from 'src/store/appointments/actions';

import {
  DaysContainer,
  Timestamps,
  TimestampsContainer,
} from './appointments.styles';
import { Button, notification, Row } from 'antd';
import {
  IAppointment,
  ICreateAppointmentInput,
} from 'src/interfaces/appointment';

interface IProps {
  modalVisible: boolean;
  closeModal: () => void;
  fetchSchedules: () => Promise<ISchedule[]>;
  createAppointment: (input: ICreateAppointmentInput) => Promise<IAppointment>;
  fetchAppointments: () => Promise<IAppointment[]>;
  appointments?: IAppointment[];
  propertyId: string;
  schedules?: ISchedule[];
}

const Appointment: React.FC<IProps> = (props) => {
  const { modalVisible, closeModal } = props;
  const [checkedDate, setCheckedDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [appointmentDate, setAppointmentDate] = useState<string>('');
  const [shortDay, setShortDay] = useState<string>('');
  const [timestamp, setTimestamp] = useState<string>('');
  const [days, setDays] = useState<string[]>([]);

  const { schedules, appointments, propertyId } = props;

  const now = new Date();

  const onChangeCheckedDate = (e: RadioChangeEvent) => {
    setCheckedDate(e.target.value);
    setTimestamp('');
  };

  useEffect(() => {
    props.fetchSchedules();
    props.fetchAppointments();
    const shortWeekDaysArray = Array.from(Array(7)).map((e, i) =>
      format(addDays(now, i), 'EEEEEE dd', { locale: pt })
    );

    shortWeekDaysArray.splice(0, 1);
    setDays(shortWeekDaysArray);
  }, []);

  const onClickDay = (day: string, index: number) => {
    const currentDay = format(addDays(now, index + 1), 'yyyy-MM-dd', {
      locale: pt,
    });
    setShortDay(day.substring(0, day.length - 3));

    setDate(currentDay);
  };

  const onClickTimestamp = (time: string) => {
    setTimestamp(time);
  };

  const createApp = () => {
    setLoading(true);
    const input: ICreateAppointmentInput = {
      propertyId,
      appointmentDate: `${date} ${timestamp}`,
      userId: store.getState().auth.user?.id!,
    };
    props
      .createAppointment(input)
      .then((res) => {
        setLoading(false);
        closeModal();
        notification.success({
          message:
            'Agendamento criado com sucesso! Você será notificado até 24 horas antes do horário marcado. Obrigado!',
        });
        props.fetchAppointments();
      })
      .catch((err) => {
        notification.error(err);
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Agendar Visitas"
      visible={modalVisible}
      className="appointment-modal"
      onCancel={closeModal}
      footer={false}
    >
      <h3>Escolha um Dia:</h3>
      <DaysContainer role="radiogroup">
        {days.map((day, index) => (
          <Fragment key={index}>
            <Radio
              id={day}
              name={day}
              value={day}
              onChange={onChangeCheckedDate}
              checked={checkedDate === day}
              onClick={() => onClickDay(day, index)}
            >
              <label htmlFor={day}>{day.replace(' ', '. ') || day}</label>
            </Radio>
          </Fragment>
        ))}
      </DaysContainer>
      {shortDay ? <h3>Escolha um horário</h3> : null}
      <TimestampsContainer>
        {schedules?.map((value, index) => (
          <Fragment key={index}>
            {value.time.map((times) => (
              <Fragment key={times}>
                {value.weekDay === shortDay ? (
                  <Timestamps
                    key={times}
                    onClick={() => onClickTimestamp(times)}
                    disabled={
                      appointments?.find(
                        (appointment) =>
                          appointment.appointmentHour === times &&
                          format(
                            new Date(appointment.appointmentDate),
                            'EEEEEE',
                            { locale: pt }
                          ) === value.weekDay
                      )
                        ? true
                        : false
                    }
                  >
                    {times.substring(0, times.length - 3)}
                  </Timestamps>
                ) : null}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </TimestampsContainer>

      <Row align="middle" justify="center" style={{ marginTop: '30px' }}>
        <Button
          htmlType="submit"
          className="btn-success"
          disabled={!timestamp}
          onClick={createApp}
        >
          Agendar Visita
        </Button>
      </Row>
    </Modal>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  schedules: state.schedules.schedules,
  appointments: state.appointments.appointments,
});

export default connect(mapStateToProps, {
  fetchSchedules,
  fetchAppointments,
  createAppointment,
})(Appointment);
