import { SizeType } from "antd/lib/config-provider/SizeContext";

interface ITable {
  bordered: boolean;
  locale: any;
  size: SizeType;
  color: string;
}

const table: ITable = {
  locale: {
    emptyText: 'Nenhum dado a ser exibido.',
  },
  size: 'small',
  bordered: true,
  color: 'black',
};

export default table;
