import { Col, Descriptions, Row } from 'antd';
import React from 'react';

import { IArea } from 'src/interfaces/property';
import styles, {
  AreaContainer,
  Title,
  Confrontations,
  ConfrontationsTitle,
} from './../styles/showProperty.styles';

interface IProps {
  area?: IArea;
}

const AreaData: React.FC<IProps> = (props) => {
  const { area } = props;
  const { Item } = Descriptions;

  return (
    <>
      {area ? (
        <>
          <Title>Área do Imóvel</Title>
          <Row>
            <Col md={12} xs={24} sm={24}>
              <AreaContainer>
                {area.privateArea ? (
                  <span>
                    Área Privativa:{' '}
                    {area.privateArea.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}{' '}
                    m²
                  </span>
                ) : null}

                {area.lotArea ? (
                  <span>
                    Área do Lote: {''}
                    {area.lotArea.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}
                    {''} m²
                  </span>
                ) : null}
              </AreaContainer>
            </Col>
            <Col md={12} xs={24}>
              <Confrontations>
                <ConfrontationsTitle>
                  Características e Confrontações
                </ConfrontationsTitle>
                {area.frontLot ? (
                  <span>
                    Frente: {''}
                    {area.frontLot.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}
                    {''} m
                  </span>
                ) : null}
                {area.leftLot ? (
                  <span>
                    Lote Direito:{' '}
                    {area.rightLot?.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}
                    {''} m
                  </span>
                ) : null}
                {area.rightLot ? (
                  <span>
                    Lote Esquerdo: {''}{' '}
                    {area.rightLot?.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}
                    {''} m
                  </span>
                ) : null}
                {area.backyard ? (
                  <span>
                    Fundos:{' '}
                    {area.backyard.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}{' '}
                    m
                  </span>
                ) : null}
              </Confrontations>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default AreaData;
