import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Row, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { FiMap, FiSliders, FiX } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import MenuSelectOptions from 'src/components/menuSelectOptions';
import PropertyCard from 'src/components/propertyCard/property.card.component';
import SearchMap from 'src/components/SearchMap';
import { IImage } from 'src/interfaces/misc';
import { IProperty } from 'src/interfaces/property';
import { IReduxState, store } from 'src/store';
import { fetchBroker } from 'src/store/broker/actions';
import {
  fetchFilteredProperties,
  fetchProperties,
} from 'src/store/property/actions';
import { CLEAR_MARKED_PROPERTIES, CLEAR_PDFS } from 'src/store/property/types';
import Filter from './components/filter';
import {
  ButtonContainer,
  MapContainer,
  PropertyContainer,
  SpinnerWrapper,
  TitleContainer,
} from './main.styles';
import { IProps } from './main.types';

type PropertyResume = {
  lat?: number;
  lng?: number;
  type: string;
  subtype?: string;
  code?: string;
  typeOfTransaction: 'sale' | 'rent';
  price: number;
  image?: IImage;
  id: string;
};

const BrokerMainScreen: React.FC<IProps> = (props) => {
  const { properties, markedProperties } = props;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [approved, setApproved] = useState<IProperty[] | undefined>();
  const [redirect, setRedirect] = useState<string>('');
  const [modal, setModal] = useState<boolean>(false);
  const [propertyResumes, setPropertyResumes] = useState<PropertyResume[] | []>(
    []
  );

  const [filtered, setFiltered] = useState<IProperty[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [startSaleValue, setStartSaleValue] = useState<number>();
  const [endSaleValue, setEndSaleValue] = useState<number>();
  const [startRentValue, setStartRentValue] = useState<number>();
  const [endRentValue, setEndRentValue] = useState<number>();

  const [form] = Form.useForm();

  const propertiesList = useRef<HTMLDivElement>(null);

  const center = {
    lat: -22.60902,
    lng: -44.230968,
  };

  const mapContainer = document.getElementById('mapContainer');

  useEffect(() => {
    setLoading(true);
    props.fetchProperties().then((res) => {
      setApproved(res.filter((property) => property.status === 'approved'));
      setFiltered(res.filter((property) => property.status === 'approved'));

      const reducedProperties = res
        .filter((property) => property.status === 'approved')
        .map((property) => {
          const { type, subtype, code, address, values } = property;
          const { latitude, longitude } = address;
          const { salePrice, rentPrice } = values;
          return {
            id: property.id,
            lat: latitude,
            lng: longitude,
            type,
            subtype,
            code,
            typeOfTransaction: salePrice ? 'sale' : 'rent',
            price: salePrice || rentPrice,
            image: property.images[0],
          } as PropertyResume;
        });
      setPropertyResumes(reducedProperties);

      setLoading(false);
    });
    props.fetchBroker().then((res) => {
      if (res.broker.status !== 'approved') {
        setRedirect('/painel/conta');
      }
    });

    return () => {
      store.dispatch({
        type: CLEAR_PDFS,
      });
      store.dispatch({
        type: CLEAR_MARKED_PROPERTIES,
      });
      setApproved(undefined);
      setFiltered(undefined);
    };
  }, []);

  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  const openMap = () => {
    if (mapContainer) mapContainer.style.width = '100%';
  };

  const closeMap = () => {
    if (mapContainer) mapContainer.style.width = '0%';
  };

  const updateStartSale = (value: any) => {
    setStartSaleValue(value);
  };

  const updateEndSale = (value: any) => {
    setEndSaleValue(value);
  };
  const updateStartRent = (value: any) => {
    setStartRentValue(value);
  };
  const updateEndRent = (value: any) => {
    setEndRentValue(value);
  };

  const onSubmitFilter = (values: any) => {
    if (values.valueType === 'Venda') {
      values.startSalePrice = startSaleValue;
      values.endSalePrice = endSaleValue;
    } else if (values.valueType === 'Locação') {
      values.startRentPrice = startRentValue;
      values.endSalePrice = endRentValue;
    }
    const { valueType, ...data } = values;
    props
      .fetchFilteredProperties({ ...data })
      .then((res) => {
        setFiltered(res);
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClearFilters = () => {
    setFiltered(properties);
    form.resetFields();
    closeModal();
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <TitleContainer>
        <h2>Pesquise por imóveis na sua região.</h2>
        <p>Utilize os filtros de busca para facilitar sua pesquisa.</p>
      </TitleContainer>
      <ButtonContainer>
        <Button onClick={openModal} icon={<FiSliders />}>
          Filtrar
        </Button>
        <Button onClick={openMap} icon={<FiMap />}>
          Exibir o Mapa
        </Button>
      </ButtonContainer>
      <PropertyContainer>
        <Row align="middle" justify="center" ref={propertiesList}>
          {!loading && filtered && filtered.length !== 0 ? (
            filtered.map((property) => (
              <PropertyCard
                key={property.id}
                property={property}
                link={`/painel/imovel/${property.id}`}
              />
            ))
          ) : (
            <SpinnerWrapper>
              {loading ? (
                <>
                  <Spin size="large" indicator={antIcon} spinning={!loading} />
                  Aguarde enquanto os imóveis são carregados...
                </>
              ) : (
                <h2>Nenhum Imóvel foi encontrado na sua região.</h2>
              )}
            </SpinnerWrapper>
          )}
        </Row>
      </PropertyContainer>
      <MapContainer id="mapContainer">
        <button onClick={closeMap} className="closeButton" type="button">
          <FiX />
        </button>
        <SearchMap center={center} propertyResumes={propertyResumes} />
        <Modal
          onCancel={() => setModal(false)}
          visible={modal}
          closable
          width="800px"
          title="Filtrar"
          className="filter-modal"
          footer={null}
        >
          <Filter
            form={form}
            onClearFilters={onClearFilters}
            updateStartSale={updateStartSale}
            updateEndSale={updateEndSale}
            updateStartRent={updateStartRent}
            updateEndRent={updateEndRent}
            onSubmit={onSubmitFilter}
          />
        </Modal>
      </MapContainer>
      {markedProperties && markedProperties.length > 0 ? (
        <MenuSelectOptions properties={markedProperties} />
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  properties: state.properties.properties,
  markedProperties: state.properties.markedProperties,
});

export default connect(mapStateToProps, {
  fetchFilteredProperties,
  fetchProperties,
  fetchBroker,
})(BrokerMainScreen);
