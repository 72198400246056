import { Descriptions } from 'antd';
import React from 'react';
import { IAddress } from 'src/interfaces/misc';

interface IProps {
  address: IAddress;
}

const AddressData: React.FC<IProps> = (props) => {
  const { address } = props;
  const { Item } = Descriptions;

  return (
    <>
      <Descriptions title="Endereço">
        <Item label="CEP"> {address.zipcode} </Item>
        <Item label="Estado"> {address.state} </Item>
        <Item label="Cidade"> {address.city} </Item>
        <Item label="Bairro"> {address.neighborhood} </Item>
        <Item label="Rua"> {address.street}</Item>
        <Item label="Complemento">
          {address.complement || 'Não Informado.'}
        </Item>
        <Item label="Condomínio"> {address.complement || 'Não Possui.'} </Item>
      </Descriptions>
      <p>
        <b>
          <i>Aqui ficará o mapa</i>
        </b>
      </p>
    </>
  );
};

export default AddressData;
