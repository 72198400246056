import React from 'react';
import { FiCompass } from 'react-icons/fi';
import { LocateContainer, LocateSearchContainer } from './styles';

interface IProps {
  panTo: (latLng: google.maps.LatLngLiteral) => void;
  isSearch?: boolean;
}

const Locate: React.FC<IProps> = (props) => {
  const { panTo } = props;

  return (
    <>
      {props.isSearch ? (
        <LocateSearchContainer>
          <button
            className="locate"
            onClick={() => {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  panTo({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                },
                () => null
              );
            }}
          >
            <FiCompass />
          </button>
        </LocateSearchContainer>
      ) : (
        <LocateContainer>
          <button
            className="locate"
            onClick={() => {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  panTo({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                },
                () => null
              );
            }}
          >
            <FiCompass />
          </button>
        </LocateContainer>
      )}
    </>
  );
};

export default Locate;
