import { Layout, Menu, notification } from 'antd';
import React, { useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import {
  FaBook,
  FaClock,
  FaHome,
  FaPlusCircle,
  FaQuestionCircle,
  FaSearch,
  FaSignOutAlt,
  FaSmileWink,
  FaUser,
  FaUserPlus,
  FaUsers,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { ReactComponent as CircleLogo } from 'src/assets/images/circle-only-logo.svg';
import { ReactComponent as TextLogo } from 'src/assets/images/text-only-logo.svg';
import { IReduxState, store } from 'src/store';
import { CLEAR_APPOINTMENTS } from 'src/store/appointments/types';
import { LOGOUT_USER } from 'src/store/auth/types';
import { CLEAR_BROKER } from 'src/store/broker/types';
import { CLEAR_OWNER } from 'src/store/owner/types';
import { Container } from './sidebar.styles';
import { IProps } from './sidebar.types';

const routes = [
  {
    path: '/painel',
    id: '0',
  },
  { path: '/painel/agendamentos', id: '1' },
  { path: '/painel/clientes', id: '2' },
  { path: '/painel/cliente/novo', id: '3' },
  { path: '/painel/conta', id: '4' },
];

const Sidebar: React.FC<IProps> = (props) => {
  const location = useLocation();

  const [redirect, setRedirect] = useState('');
  const [visible, setVisible] = useState(false);
  const [toggle, setToggle] = useState(false);
  const current = [
    `${routes.find((item) => item.path === location.pathname)?.id}`,
  ];

  const handleLogout = async () => {
    store.dispatch({
      type: CLEAR_BROKER,
    });

    store.dispatch({
      type: CLEAR_APPOINTMENTS,
    });

    await store.dispatch({
      type: LOGOUT_USER,
    });
    setRedirect('/');
  };

  const handleOwnerLogout = () => {
    store.dispatch({
      type: CLEAR_OWNER,
    });

    store.dispatch({
      type: LOGOUT_USER,
    });
    setRedirect('/');
  };

  const handleHelp = () => {
    notification.info({
      message: (
        <p>
          Está com alguma dúvida e/ou um problema em utilizar o sistema? Envie
          um e-mail para <b>suporte@embrane.com.br</b>
          <br />
          Ou mande uma mensagem para nós pelo número <b>(22) 99999-9999</b>
        </p>
      ),
      icon: <FaSmileWink />,
    });
  };

  const onOpenDrawer = () => {
    setVisible(true);
  };

  const onCloseDrawer = () => {
    setVisible(false);
  };

  const onCollapse = () => {
    setToggle(!toggle);
  };

  const { auth } = props;
  const { Sider } = Layout;
  const { Item } = Menu;

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      {!!auth ? (
        <Sider
          collapsedWidth="80"
          trigger={null}
          collapsible
          style={{
            overflow: 'hidden',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
          width={255}
          collapsed={toggle}
        >
          <Container>
            {auth.role === 'broker' ? (
              <>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={current}>
                  <div className="logo">
                    {!toggle ? (
                      <div>
                        <CircleLogo className="spinning-logo" />
                        <TextLogo className="text-logo" />
                      </div>
                    ) : (
                      <div>
                        <CircleLogo className="collapsedSpinningLogo" />
                      </div>
                    )}
                    <button onClick={() => onCollapse()} className="toggle">
                      {toggle ? <BsChevronRight /> : <BsChevronLeft />}
                    </button>
                  </div>
                  <Item key="0" icon={<FaSearch />}>
                    <Link to="/painel">Pesquisar Imóveis</Link>
                  </Item>
                  <Item key="1" icon={<FaBook />}>
                    <Link to="/painel/agendamentos">Meus agendamentos</Link>
                  </Item>
                  <Item key="2" icon={<FaUsers />}>
                    <Link to="/painel/clientes"> Meus Clientes</Link>
                  </Item>
                  <Item key="3" icon={<FaUserPlus />}>
                    <Link to="/painel/cliente/novo"> Novo Cliente </Link>
                  </Item>
                  <div className="separator" />
                  <Item key="4" icon={<FaUser />}>
                    <Link to="/painel/conta">Minhas Informações</Link>
                  </Item>
                  <Item
                    key="5"
                    icon={<FaQuestionCircle />}
                    onClick={handleHelp}
                  >
                    Ajuda
                  </Item>

                  <Item key="9" onClick={handleLogout} icon={<FaSignOutAlt />}>
                    Sair
                  </Item>
                </Menu>
              </>
            ) : (
              <>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={current}>
                  <div className="logo">
                    {!toggle ? (
                      <div>
                        <CircleLogo className="spinning-logo" />
                        <TextLogo className="text-logo" />
                      </div>
                    ) : (
                      <div>
                        <CircleLogo className="collapsedSpinningLogo" />
                      </div>
                    )}
                    <button onClick={() => onCollapse()} className="toggle">
                      {toggle ? <BsChevronRight /> : <BsChevronLeft />}
                    </button>
                  </div>
                  <Item key="0" icon={<FaHome />}>
                    <Link to="/painel">Início</Link>
                  </Item>
                  <Item key="1" icon={<FaBook />}>
                    <Link to="/painel/imoveis/">Meus Imóveis</Link>
                  </Item>

                  <Item key="2" icon={<FaClock />}>
                    <Link to="/painel/imoveis/pendentes">Pendentes</Link>
                  </Item>

                  <Item key="3" icon={<FaPlusCircle />}>
                    <Link to="/painel/imoveis/cadastrar">Cadastrar imóvel</Link>
                  </Item>
                  <Item key="4" icon={<FaUser />}>
                    <Link to="/painel/configurações">Configurações</Link>
                  </Item>
                  <Item
                    key="5"
                    onClick={handleHelp}
                    icon={<FaQuestionCircle />}
                  >
                    Ajuda
                  </Item>
                  <Item
                    key="9"
                    onClick={handleOwnerLogout}
                    icon={<FaSignOutAlt />}
                  >
                    Sair
                  </Item>
                </Menu>
              </>
            )}
          </Container>
        </Sider>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  auth: state.auth.user,
});

export default connect(mapStateToProps)(Sidebar);
