import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from 'src/assets/images/logo_SVG.svg';
import { brokerLogin, ownerLogin } from 'src/store/auth/actions';
import { Wrapper } from './styles';

const AuthScreen: React.FC = () => {
  console.log('Hello World');

  return (
    <>
      <Wrapper>
        <LogoSVG />
        <div>
          <Link to="/proprietario/">Proprietário</Link>
          <Link to="/corretor/">Corretor</Link>
        </div>
      </Wrapper>
    </>
  );
};

export default connect(undefined, { brokerLogin, ownerLogin })(AuthScreen);
