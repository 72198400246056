import { Dispatch } from "react";
import { scheduleService } from "src/services/schedule";
import { FETCH_SCHEDULES, ScheduleActionTypes } from "./types";

export const fetchSchedules = () => (dispatch: Dispatch<ScheduleActionTypes>) => {
  return scheduleService.fetchSchedules().then((res) =>  {
    dispatch({
      type: FETCH_SCHEDULES,
      payload: res,
    })
    return res;
  }).catch(() => {
    throw Error;
  })
}