import styled from 'styled-components';

export const Container = styled.div`
  width: 80px;
  height: 80px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    padding-top: 5px;
    font-size: 13px;
    font-weight: bold;
  }
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  transition: 1s all smooth;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: var(--app-quarternary-color);
  color: #fff;
  span {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Descriptions = styled.div`
  font-size: 14px;
  font-weight: bold;
`;