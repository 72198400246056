import { Col, Descriptions, Row, Tag } from 'antd';
import React, { Fragment } from 'react';
import { FaBed, FaCar, FaCouch, FaShower } from 'react-icons/fa';
import { IoIosBed } from 'react-icons/io';
import { MdKitchen } from 'react-icons/md';

import { Title } from '../styles/showProperty.styles';
import { ICaracteristics, IRural } from 'src/interfaces/property';

import Caracteristics from 'src/components/caracteristics/caracteristics.component';

interface IProps {
  caracteristics?: ICaracteristics;
  extraHouses?: ICaracteristics[];
  type: string;
  rural?: IRural;
}

const CaracteristicsData: React.FC<IProps> = (props) => {
  const { caracteristics, type, rural, extraHouses } = props;
  const { Item } = Descriptions;

  return (
    <>
      <Title>Características</Title>
      {type !== 'Terreno ' ? (
        <>
          <Row gutter={16} justify="center" style={{ paddingBottom: '15px' }}>
            {caracteristics?.rooms ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  description="Quarto"
                  amount={caracteristics.rooms}
                  icon={<FaBed />}
                />
              </Col>
            ) : null}

            {caracteristics?.suites ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  description="Suite"
                  amount={caracteristics.suites}
                  icon={<IoIosBed />}
                />
              </Col>
            ) : null}

            {caracteristics?.bathrooms ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  description="Banheiro"
                  amount={caracteristics.bathrooms}
                  icon={<FaShower />}
                />
              </Col>
            ) : null}

            {caracteristics?.livingRooms ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  description="Sala"
                  amount={2}
                  icon={<FaCouch />}
                />
              </Col>
            ) : null}

            {caracteristics?.garages && caracteristics.garages >= 0 ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  description={
                    caracteristics.garages >= 1 ? 'garagen' : 'garagem'
                  }
                  amount={caracteristics.garages}
                  icon={<FaCar />}
                />
              </Col>
            ) : null}

            {caracteristics?.kitchens && caracteristics.kitchens ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  description="Cozinha"
                  amount={caracteristics?.kitchens}
                  icon={<MdKitchen />}
                />
              </Col>
            ) : null}
          </Row>
          <Descriptions>
            {caracteristics?.balconies ? (
              <Item label="Varandas">{caracteristics?.balconies}</Item>
            ) : null}

            {caracteristics?.balconies ? (
              <Item label="Área Técnica">
                {caracteristics?.technicalAreas || 'Não Informado'}
              </Item>
            ) : null}

            {caracteristics?.toilets ? (
              <Item label="Lavabo">
                {caracteristics?.toilets || 'Não Informado'}
              </Item>
            ) : null}

            {caracteristics?.dependencies ? (
              <Item label="Dependência">
                {caracteristics?.dependencies || 'Não Informado'}
              </Item>
            ) : null}
          </Descriptions>
          <span>
            {caracteristics?.others ? (
              <>
                {caracteristics.others.map((other) => (
                  <Tag key={other} color="#f0f0f0" style={{ color: '#000' }} />
                ))}
              </>
            ) : null}
          </span>
        </>
      ) : null}

      {rural ? (
        <>
          <Descriptions>
            <Item label="Hectáres">
              {rural.acres?.toLocaleString('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
              })}
            </Item>
            <Item label="Bretes">{rural.bretes}</Item>
            <Item label="Currais"> {rural.corrals} </Item>
            <Item label="Embarcadouros"> {rural.docks} </Item>
            <Item label="Tronco"> {rural.trunks} </Item>
            <Item label="Aguardas"> {rural.waits} </Item>
            <Item label="Balanças"> {rural.scales} </Item>
          </Descriptions>
          {extraHouses
            ? extraHouses.map((house, index) => (
                <Fragment key={index}>
                  <Title style={{ margin: '25px 0' }}>
                    Casa Extra - {index + 1}
                  </Title>
                  <Descriptions layout="horizontal" size="small">
                    <Item label="Quartos">{house.rooms}</Item>
                    <Item label="Suítes">{house.suites}</Item>
                    <Item label="Banheiros">{house.bathrooms}</Item>
                    <Item label="Salas">{house.livingRooms}</Item>

                    <Item label="Garagem">{house.garages}</Item>
                    <Item label="Cozinhas">{house.kitchens}</Item>

                    <Item label="Varandas">{house.balconies}</Item>
                    <Item label="Área Técnica">{house.technicalAreas}</Item>
                    <Item label="Lavabos"> {house.toilets} </Item>
                    <Item label="Dependências"> {house.dependencies} </Item>
                  </Descriptions>
                </Fragment>
              ))
            : null}
        </>
      ) : null}
    </>
  );
};

export default CaracteristicsData;
