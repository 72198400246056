import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { decorator, maritalStatus, states } from 'src/utils';

interface IProps {
  sending?: boolean;
}

const PersonForm: React.FC<IProps> = (props) => {
  const [state, setState] = useState('');
  const { Option } = Select;

  return (
    <>
      <Row gutter={16}>
        <Col lg={12} md={6} xs={24} sm={12}>
          <Form.Item label="Nome" name="name" rules={decorator.name}>
            <Input size="middle" placeholder="Insira seu Nome..." />
          </Form.Item>
        </Col>
        <Col md={6} xs={24} sm={12}>
          <Form.Item label="E-mail" name="email" rules={decorator.email}>
            <Input size="middle" placeholder="Insira seu E-mail..." />
          </Form.Item>
        </Col>
        <Col md={6} xs={24} sm={12}>
          <Form.Item label="Telefone" name="phone" rules={decorator.phone}>
            <InputMask mask="(99) 99999-9999">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  size="middle"
                  placeholder="Insira seu Telefone..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item
            label="Data de Nascimento"
            name="birthdate"
            rules={decorator.birthdate}
          >
            <InputMask mask="99/99/9999">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  size="middle"
                  placeholder="Insira sua data..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item
            label="Estado Civíl"
            name="maritalStatus"
            rules={decorator.maritalStatus}
          >
            <Select size="middle" placeholder="Insira o Estado Civil...">
              {maritalStatus.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item
            label="Profissão"
            name="profession"
            rules={decorator.profession}
          >
            <Input size="middle" placeholder="Insira a profissão..." />
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={24}>
          <Form.Item label="CPF" name="cpf" rules={decorator.cpf}>
            <InputMask mask="999.999.999-99">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  size="middle"
                  placeholder="Insira o CPF..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item
            label="CEP"
            name={['address', 'zipcode']}
            rules={decorator.zipcode}
          >
            <InputMask mask="99999-999">
              {(inputProps: string) => (
                <Input
                  size="middle"
                  {...inputProps}
                  placeholder="Insira o CEP..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col lg={6} md={3} xs={24} sm={12}>
          <Form.Item
            label="Estado"
            name={['address', 'state']}
            rules={decorator.state}
          >
            <Select>
              {states.map((item) => (
                <Option key={item.key} value={item.uf}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={6} md={4} xs={24} sm={12}>
          <Form.Item
            label="Cidade"
            name={['address', 'city']}
            rules={decorator.city}
          >
            <Input size="middle" placeholder="Insira a cidade..." />
          </Form.Item>
        </Col>
        <Col lg={6} md={4} xs={24} sm={12}>
          <Form.Item
            label="Bairro"
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
          >
            <Input size="middle" placeholder="Insira o bairro..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item
            label="Rua"
            name={['address', 'street']}
            rules={decorator.street}
          >
            <Input size="middle" placeholder="Insira a rua..." />
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item
            label="Número"
            name={['address', 'number']}
            rules={decorator.streetNumber}
          >
            <Input placeholder="Insira o número..." />
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item
            label="Complemento"
            name={['address', 'complement']}
            rules={decorator.complement}
          >
            <Input placeholder="Insira o complemento (Opcional)..." />
          </Form.Item>
        </Col>
        <Col lg={6} md={6} xs={24} sm={12}>
          <Form.Item label="Senha" name="password" rules={decorator.password}>
            <Input.Password placeholder="Insira a sua senha de acesso..." />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PersonForm;
