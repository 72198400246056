import { message as messageAnt, notification as notificationAnt } from 'antd';

const forms = {
  errors: ({ graphQLErrors, networkError }: any) => {
    console.log(graphQLErrors);
    let error = false;
    if (graphQLErrors.length) {
      graphQLErrors.map((item: any) => {
        if (graphQLErrors[0].code === 'CUSTOM_ERROR') {
          const { message } = graphQLErrors[0];
          notificationAnt.error({
            message: message,
          });
          error = true;
          return true;
        }
        if (
          item.extensions &&
          item.extensions.errors &&
          item.extensions.errors.length
        ) {
          item.extensions.errors.map((err: any) => {
            notificationAnt.error({
              message: err
            });
            error = true;
            return true;
          });
        }
        return true;
      });
    }

    if (!error) {
      messageAnt.error(
        'Ocorreu um erro no servidor, por favor tente novamente mais tarde.'
      );

      return;
    }
  },
};

export default forms;
