import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import InputMask from 'react-input-mask';

import { decorator, states } from 'src/utils';

interface IProps {
  sending?: boolean;
}

const CompanyForm: React.FC<IProps> = (props) => {
  const [state, setState] = useState('');
  const { sending } = props;

  const { Option } = Select;

  const handleState = (e: any, option: any) => {
    setState(e);
  };

  return (
    <>
      <Row gutter={16}>
        <Col md={12} lg={12} xs={24} sm={12}>
          <Form.Item
            label="Nome da Empresa"
            name="companyName"
            rules={decorator.name}
          >
            <Input placeholder="Insira o Nome da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6} xs={24} sm={12}>
          <Form.Item label="E-mail" name="email" rules={decorator.email}>
            <Input placeholder="Insira o E-mail da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6} xs={24} sm={24}>
          <Form.Item label="Telefone" name="phone" rules={decorator.phone}>
            <InputMask mask="(99) 99999-9999">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira o telefone da empresa..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={6} lg={4} xs={24} sm={12}>
          <Form.Item
            label="Estado"
            name={['address', 'state']}
            rules={decorator.state}
          >
            <Select onChange={handleState}>
              {states.map((item) => (
                <Option key={item.key} value={item.uf}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={6} lg={8} xs={24} sm={12}>
          <Form.Item label="CRECI" name="creci" rules={decorator.creci}>
            <InputMask disabled={!state} mask={`${state}-999999/J`}>
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  disabled={!state}
                  placeholder="Insira seu CRECI..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xs={24} sm={24}>
          <Form.Item label="CPF" name="cpf" rules={decorator.cpf}>
            <InputMask mask="999.999.999-99">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira seu CPF..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={4} lg={4} xs={24} sm={12}>
          <Form.Item
            label="CEP"
            name={['address', 'zipcode']}
            rules={decorator.zipcode}
          >
            <InputMask mask="99999-999">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira seu CEP..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={8} lg={8} xs={24} sm={12}>
          <Form.Item
            label="Cidade"
            name={['address', 'city']}
            rules={decorator.city}
          >
            <Input placeholder="Insira sua Cidade..." />
          </Form.Item>
        </Col>
        <Col md={6} lg={6} xs={24} sm={12}>
          <Form.Item
            label="Bairro"
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
          >
            <Input placeholder="Insira seu Bairro..." />
          </Form.Item>
        </Col>
        <Col md={6} lg={6} xs={24} sm={12}>
          <Form.Item
            label="Número"
            name={['address', 'number']}
            rules={decorator.streetNumber}
          >
            <Input placeholder="Insira o número..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12} lg={12} xs={24} sm={12}>
          <Form.Item
            label="Complemento"
            name={['address', 'complement']}
            rules={decorator.complement}
          >
            <Input placeholder="Insira o seu complemento (Opcional)..." />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xs={24} sm={12}>
          <Form.Item label="Senha" name="password" rules={decorator.password}>
            <Input.Password placeholder="Insira sua senha de Acesso..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} justify="center" align="middle">
        <Button className="btn-primary" htmlType="submit" loading={sending}>
          Avançar
        </Button>
      </Row>
    </>
  );
};

export default CompanyForm;
