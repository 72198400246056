import { Form, Row, Col, Select, Input, Radio, Button } from 'antd';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FaSearch } from 'react-icons/fa';
import { IoIosOptions } from 'react-icons/io';

import { FilterContainer, FilterFormFooter } from '../main.styles';
import { decorator, types } from 'src/utils';
import { FormInstance } from 'antd/lib/form';

interface IProps {
  onSubmit: (values: any) => void;
  updateStartSale: (value: any) => void;
  updateEndSale: (value: any) => void;
  updateStartRent: (value: any) => void;
  updateEndRent: (value: any) => void;
  onClearFilters: (value: any) => void;
  form: FormInstance;
}

const Filter: React.FC<IProps> = (props) => {
  const { Option } = Select;
  const { Group } = Radio;
  const [valueType, setValueType] = useState('');
  const [startValue, endValue] = useState();
  const {
    onSubmit,
    updateStartSale,
    updateEndSale,
    updateStartRent,
    updateEndRent,
    onClearFilters,
    form,
  } = props;

  const { Item } = Form;

  const handleCheck = (e: RadioChangeEvent) => {
    setValueType(e.target.value);
  };

  const checkRange = () => {
    if (valueType === 'Venda') {
      const startPrice = form.getFieldValue('startSalePrice');
    }
  };

  return (
    <FilterContainer>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Row gutter={8}>
          <Col md={24} xs={24} sm={24} lg={24}>
            <Item name="type" label="Tipo do Imóvel">
              <Select placeholder="Insira o tipo...">
                {types.propertyTypes.map((el) => (
                  <Option key={el.label} value={el.label}>
                    {el.label}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col md={12} xs={24} sm={12} lg={12}>
            <Item name="zipcode" label="CEP do Imóvel">
              <Input placeholder="Insira o CEP..." />
            </Item>
          </Col>
          <Col md={12} xs={24} sm={12} lg={12}>
            <Item name="city" label="Cidade">
              <Input placeholder="Insira a cidade..." />
            </Item>
          </Col>

          <Col md={12} xs={24} sm={12} lg={12}>
            <Item name="neighborhood" label="Bairro">
              <Input placeholder="Insira o bairro..." />
            </Item>
          </Col>
          <Col md={12} xs={24} sm={12} lg={12}>
            <Item name="street" label="Rua">
              <Input placeholder="Insira a rua..." />
            </Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col md={8} xs={12} sm={12} lg={6}>
            <Item name="valueType" label=" ">
              <Group onChange={handleCheck}>
                <Radio value="Venda" checked={valueType === 'Venda'}>
                  Venda
                </Radio>
                <Radio value="Locação" checked={valueType === 'Locação'}>
                  Locação
                </Radio>
              </Group>
            </Item>
          </Col>

          {valueType === 'Venda' ? (
            <>
              <Col md={6} xs={12} sm={12} lg={8}>
                <Item
                  name="startSalePrice"
                  label="Valor de Venda"
                  rules={decorator.values}
                >
                  <NumberFormat
                    placeholder="Insira o valor de venda..."
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'R$ '}
                    onValueChange={(value) => updateStartSale(value.floatValue)}
                    decimalScale={2}
                    customInput={Input}
                  />
                </Item>
              </Col>

              <Col md={6} xs={12} sm={12} lg={8}>
                <Item name="endSalePrice" label="Até:" rules={decorator.values}>
                  <NumberFormat
                    placeholder="Insira o valor de venda..."
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'R$ '}
                    onValueChange={(value) => updateEndSale(value.floatValue)}
                    decimalScale={2}
                    customInput={Input}
                  />
                </Item>
              </Col>
            </>
          ) : valueType === 'Locação' ? (
            <>
              <Col md={6} xs={12} sm={12} lg={8}>
                <Item
                  name="startRentPrice"
                  label="Valor de Locação"
                  rules={decorator.values}
                >
                  <NumberFormat
                    placeholder="Insira o valor de locação..."
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    onValueChange={(value) => updateStartRent(value.floatValue)}
                    prefix={'R$ '}
                    decimalScale={2}
                    customInput={Input}
                  />
                </Item>
              </Col>
              <Col md={6} xs={12} sm={12} lg={8}>
                <Item name="endRentPrice" label="Até" rules={decorator.values}>
                  <NumberFormat
                    placeholder="Insira o valor de locação..."
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'R$ '}
                    onValueChange={(value) => updateEndRent(value.floatValue)}
                    decimalScale={2}
                    customInput={Input}
                  />
                </Item>
              </Col>
            </>
          ) : null}
        </Row>
        <FilterFormFooter>
          <Row align="middle" justify="space-between">
            <Button onClick={onClearFilters} className="btn-filter">
              Limpar Filtros
            </Button>

            <Button
              icon={<FaSearch />}
              htmlType="submit"
              className="btn-search-filter"
            >
              Pesquisar
            </Button>
          </Row>
        </FilterFormFooter>
      </Form>
    </FilterContainer>
  );
};

export default Filter;
