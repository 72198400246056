import { IUser } from "src/interfaces/user";

export const FETCH_OWNER = 'FETCH_OWNER';
export const CLEAR_OWNER = 'CLEAR_OWNER';

export interface IReducerOwnerState {
  owner?: IUser;
}

export interface IFetchOwner {
  type: typeof FETCH_OWNER;
  payload: IUser;
}

export interface IClearOwner {
  type: typeof CLEAR_OWNER;
}

export type OwnerActionTypes = IFetchOwner | IClearOwner;