import gql from "graphql-tag";
import client from "./client.auth";

export const scheduleService = {

  fetchSchedules: () =>
    client
      .query({
        query: gql`
          query fetchSchedules {
            schedules {
              id
              weekDay
              time
            }
          }
        `,
      }).then(res => res.data.schedules),
}

