import { Button, Divider, Form, notification } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'src/components';

import { IReduxState, store } from 'src/store';
import { createProperty } from 'src/store/property/actions';
import AddressForm from './components/createProperty/address.component';
import CaracteristicsForm from './components/createProperty/caracteristics.component';
import CategoryForm from './components/createProperty/category.component';
import PriceForm from './components/createProperty/values.component';
import RuralForm from './components/createProperty/rural/rural.component';
import AreaForm from './components/createProperty/area.component';
import DocumentsForm from './components/createProperty/documents.component';
import ImagesForm from './components/createProperty/images.component';

import { IProps } from './types/create.property.types';
import { Redirect } from 'react-router-dom';
import { IMarker } from 'src/interfaces/misc';
import FuseAddress from 'src/components/fuseAddress';

const CreatePropertyScreen: React.FC<IProps> = (props) => {
  const [sending, setSending] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [category, setCategory] = useState('');
  const [zone, setZone] = useState('');
  const [marker, setMarker] = useState<IMarker>();

  const [form] = Form.useForm();

  const onAddressChange = async (name: string) => {
    const addressFields = form.getFieldsValue(['address']);

    let formattedAddress;

    formattedAddress = `${addressFields.address.zipcode} ${addressFields.address.state} ${addressFields.address.city} ${addressFields.address.neighborhood} ${addressFields.address.street} ${addressFields.address.number}`;
    console.log(name);
    console.log(formattedAddress);
    if (name === 'number') {
      await FuseAddress(formattedAddress).then((res) => {
        form.setFieldsValue({
          address: {
            latitude: res.lat,
            longitude: res.lng,
          },
        });
        setMarker({
          lat: res.lat,
          lng: res.lng,
        });
      });
    }
  };

  const handleCheck = (e: RadioChangeEvent) => {
    setZone(e.target.value);
  };

  const onCategoryChange = (value: any, option: any) => {
    setCategory(value);
  };

  const onFinish = (values: any) => {
    setSending(true);
    values.userId = store.getState().auth.user?.id;
    props
      .createProperty(values)
      .then((res) => {
        setSending(false);
        notification.success({
          message:
            'Imóvel Cadastrado com sucesso! Por favor aguarde enquanto nossa equipe verifica as informações do seu imóvel.',
        });
        setRedirect(`painel/imoveis/${res.id}`);
      })
      .catch(() => {
        setSending(false);
      });
  };

  return (
    <Card
      title="Cadastrar Imóvel"
      subtitle="Preencha o formulário abaixo para cadastrar seus imóveis."
    >
      {!!redirect && <Redirect to={redirect} />}
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <AddressForm marker={marker} onAddressChange={onAddressChange} />
        <CategoryForm
          handleCheck={handleCheck}
          onCategoryChange={onCategoryChange}
          category={category}
        />
        {zone === 'Urbana' && category !== 'Terreno' ? (
          <CaracteristicsForm />
        ) : null}
        {zone === 'Rural' && category !== 'Terreno' ? <RuralForm /> : null}

        {zone === 'Urbana' || category === 'Terreno' ? <AreaForm /> : null}
        <PriceForm />

        <DocumentsForm />

        <ImagesForm />

        <Divider />
        <h3>Verifique todas as suas informações antes de prosseguir.</h3>
        <br />
        <Button htmlType="submit" loading={sending}>
          Avançar
        </Button>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  property: state.properties.property,
});

export default connect(mapStateToProps, { createProperty })(
  CreatePropertyScreen
);
