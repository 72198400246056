import styled from 'styled-components';

export const Container = styled.div`
  width: 260px;
  min-height: 380px;
  margin: 15px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: relative;
  background: var(--app-secondary-color);
  .propertyCode {
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    font-family: Roboto;
    color: var(--white);
    padding: 0 0.5rem;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: var(--app-secondary-color);
    transform: translate(10%, 35%);
  }

  .propertyCheck {
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 0;
    transform: translate(-30%, -10%);
  }
`;

export const MainInfo = styled.div`
  padding: 15px;
  span {
    text-align: left;
    color: rgba(66, 66, 66, 1);
    font-size: 0.95em;
    margin: 0;
    font-weight: 500;
    line-height: 1.125em;
  }

  p {
    text-align: left;
    color: rgba(44, 44, 44, 1);
    font-size: 1.05em;
    margin: 0;
    padding: 5px 0 0 0;
    font-weight: 600;
    line-height: 1.125em;
  }

  .city-state {
    text-align: left;
  }
`;

export const CaracteristicsInfo = styled.div`
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  span {
    margin-right: 10px;
    display: flex;
    align-items: center;
    height: auto;
    color: rgba(0, 0, 0, 0.54);
    text-align: inherit;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    svg {
      margin-right: 5px;
      font-size: 16px;
    }
  }
`;

export const PriceInfo = styled.div`
  padding: 15px;

  p {
    margin: 0;
  }

  span {
    color: rgba(0, 120, 68, 1);
    font-size: 1em;
    font-weight: 500;
    line-height: 1.125em;
  }
`;
