import { LoadingOutlined } from '@ant-design/icons';
import { Col, Descriptions, notification, Row, Spin, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Card } from 'src/components';
import { IUser } from 'src/interfaces/user';
import { IReduxState } from 'src/store';
import { colors, status } from 'src/utils';

interface IProps {
  broker?: IUser;
}

const BrokerAccount: React.FC<IProps> = (props) => {
  const { broker } = props;
  const { Item } = Descriptions;
  const [loading, setLoading] = useState<boolean>(true);
  const antIcon = <LoadingOutlined spin />;

  useEffect(() => {
    if (broker?.broker.status !== 'approved') {
      notification.warning({
        message:
          'Seu cadastro ainda não foi aprovado pelo nosso sistema. Enviaremos uma notificação a você quando seu cadastro for aprovado!  ',
      });
    }
    setLoading(false);
  }, [broker]);

  return (
    <Card>
      {loading ? (
        <Spin spinning={loading} indicator={antIcon} />
      ) : (
        <>
          <Row gutter={16}>
            <Col md={20} lg={20} xs={16} sm={8}>
              <h1> {broker?.name || broker?.companyName} </h1>
            </Col>
            <Col md={4} lg={4} xs={8} sm={12}>
              {broker?.broker.status === 'pendent' ? (
                <Tag color={colors.yellow[900]}>
                  {
                    status.find(
                      (value) => value.original === broker.broker.status
                    )?.label
                  }
                </Tag>
              ) : broker?.broker.status === 'approved' ? (
                <Tag color={colors.green[900]}>
                  {
                    status.find(
                      (value) => value.original === broker.broker.status
                    )?.label
                  }
                </Tag>
              ) : (
                <Tag color={colors.red[600]}>
                  {
                    status.find(
                      (value) => value.original === broker?.broker.status
                    )?.label
                  }
                </Tag>
              )}
            </Col>
          </Row>
          <br />
          <h3> Informações Básicas: </h3>
          <Descriptions
            size="middle"
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            {broker?.cpf ? (
              <Item label="CPF"> {broker.cpf} </Item>
            ) : (
              <Item label="CNPJ"> {broker?.cnpj} </Item>
            )}
            <Item label="CRECI"> {broker?.broker.creci} </Item>
            <Item label="Data de Nascimento"> {broker?.birthdate} </Item>
            <Item label="E-mail"> {broker?.email} </Item>
            <Item label="Inscrição Estadual">
              {broker?.broker.stateRegistration || 'Não Informado.'}
            </Item>
            <Item label="Telefone"> {broker?.phone} </Item>
          </Descriptions>
          <br />
          <h3>Endereço: </h3>
          <Descriptions
            size="middle"
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Item label="Endereço">
              {broker?.broker.address.street}, {broker?.broker.address.number} -{' '}
              {broker?.broker.address.neighborhood}
            </Item>
            <Item label="Complemento">
              {broker?.broker.address.complement || 'Não Informado.'}
            </Item>
            <Item label="Cidade">
              {broker?.broker.address.city} - {broker?.broker.address.state}
            </Item>
            <Item label="CEP">{broker?.broker.address.zipcode}</Item>
          </Descriptions>
        </>
      )}
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  broker: state.broker.broker,
});

export default connect(mapStateToProps)(BrokerAccount);
