import styled from 'styled-components';



export const DaysContainer = styled.div`

  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;


  .ant-radio-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 40px;
    padding: 25px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    text-transform: capitalize;
    word-break: break-word;

    label {
      cursor: pointer;
    }
    
  transition: 0.5s all ease-in-out;
    .ant-radio  {
      display: none;
    }
  }


  .ant-radio-wrapper.ant-radio-wrapper-checked {
    border: 1px solid rgba(51, 51, 51, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--app-secondary-color);
    background: var(--app-quarternary-color);
    
  }
`;


export const DayButton = styled.input`
  width: 50px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
  border-radius: 40px;
  background-color: var(--app-secondary-color);
  font-size: 14px;
  font-weight: 500; 
  margin: 0 5px;
  cursor: pointer;
  color: var(--app-terciary-color);
  
  transition: 0.5s all ease-in-out;

  &:focus, &:active, &:hover {
  
    transition: 0.5s all ease-in-out;
    background-color: var(--app-quarternary-color);
    color: var(--app-secondary-color);
    border: 1px solid rgba(51, 51, 51, 0.4);
    
  }
  
`;


export const TimestampsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;



export const Timestamps = styled.button`
  width: 64px;
  height: 40px;
  outline: none;
  margin: 5px 5px;
  cursor: pointer;
  background-color: var(--app-secondary-color);
  border: 1px solid rgba(51, 51, 51, 0.4);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;

  
  transition: 0.5s all ease-in-out;

  &:focus, &:active, &:hover {
    transition: 0.5s all ease-in-out;
    background-color: var(--app-quarternary-color);
    color: var(--app-secondary-color);
    border: 1px solid rgba(51, 51, 51, 0.4);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background: var(--app-primary-color);
    &:hover {
      opacity: 0.6;
      color: var(--app-terciary-color); 
    }
  } 


`;