const states = [
  { key: 1, uf: 'AC', name: 'Acre' },
  { key: 2, uf: 'AL', name: 'Alagoas' },
  { key: 3, uf: 'AP', name:'Amapá' },
  { key: 4, uf: 'AM', name: 'Amazonas' },
  { key: 5, uf: 'BA', name: 'Bahia' },
  { key: 6, uf: 'CE', name: 'Ceará' },
  { key: 7, uf: 'DF', name: 'Distrito Federal' },
  { key: 8, uf: 'ES', name: 'Espírito Santo' },
  { key: 9, uf: 'GO', name: 'Goiás' },
  { key: 10, uf: 'MA', name: 'Maranhão' },
  { key: 11, uf: 'MG', name: 'Minas Gerais' },
  { key: 12, uf: 'MT', name: 'Mato Grosso' },
  { key: 13, uf: 'MS', name: 'Mato Grosso do Sul' },
  { key: 14, uf: 'PA', name: 'Pará' },
  { key: 15, uf: 'PB', name: 'Paraíba' },
  { key: 16, uf: 'PE', name: 'Pernambuco' },
  { key: 17, uf: 'PI', name: 'Piauí' },
  { key: 18, uf: 'PR', name: 'Paraná' },
  { key: 19, uf: 'RJ', name: 'Rio de Janeiro' },
  { key: 20, uf: 'RN', name: 'Rio Grande do Norte' },
  { key: 21, uf: 'RS', name: 'Rio Grande do Sul' },
  { key: 22, uf: 'RO', name: 'Rondônia' },
  { key: 23, uf: 'RR', name: 'Roraima' },
  { key: 24, uf: 'SC', name: 'Santa Catarina' },
  { key: 25, uf: 'SP', name: 'São Paulo' },
  { key: 26, uf: 'SE', name: 'Sergipe' },
  { key: 27, uf: 'TO', name: 'Tocantins' },
]

export default states;