import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import { useSelector } from 'react-redux';
import PropertyPDF from 'src/components/propertyPDF';
import { IReduxState } from 'src/store';

const Playground: React.FC = () => {
  const { properties } = useSelector((state: IReduxState) => state?.properties);

  return (
    <PDFViewer height="100%" width="100%">
      <PropertyPDF property={properties![2]} />
    </PDFViewer>
  );
};

export default Playground;
