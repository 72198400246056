import { AppointmentActionTypes, CLEAR_APPOINTMENTS, CREATE_APPOINTMENT, FETCH_APPOINTMENTS, IReducerAppointmentState } from "./types";
import storage from 'redux-persist/es/storage';
import { persistReducer } from "redux-persist";


const initialState: IReducerAppointmentState = {
  appointment: undefined,
  appointments: undefined,
}

const persistConfig = {
  storage,
  key: 'appointments',
  whitelist: ['appointments']
};

const reducer = (state = initialState, action: AppointmentActionTypes) => {
  switch (action.type) {
    case FETCH_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      }
    case CREATE_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
      }

    case CLEAR_APPOINTMENTS:
      return {
        ...state,
        appointments: []
      }
    default:
      return state;
  }
}

export default persistReducer(persistConfig, reducer);