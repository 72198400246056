import gql from "graphql-tag";
import { ICreateAppointmentInput } from "src/interfaces/appointment";
import client from "./client.auth";

export const appointmentService = {

  fetchAppointments: () =>
    client
      .query({
        query: gql`
          query fetchAppointments {
            appointments {
              id
              appointmentDate
              appointmentHour
              property {
                id
                type
                subtype
                address {
                  city
                  neighborhood
                  street
                  number
                  
                }
              }
              broker {
                id
                # broker {
                #   id
                # }
              }
              createdAt
              updatedAt
            }
          }
        `,
      }).then(res => res.data.appointments),


  createAppointment: (input: ICreateAppointmentInput) =>
    client
      .mutate({
        mutation: gql`
          mutation createAppointment($input: CreateAppointmentInput!) {
            createAppointment(input: $input) {
              id
              appointmentHour
              appointmentDate
              broker {
                id
                broker {
                  id
                }
              }
              property {
                id
              }
            }
          }
        `,
        variables: { input }
      }).then(res => res.data.createAppointment),

  
}