import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { IMarker } from 'src/interfaces/misc';
import Locate from '../locate';
import Search from '../search';

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

const libraries: Libraries = ['places'];

interface IPosition {
  lat: number;
  lng: number;
}

const mapStyles = {
  width: '100%',
  height: '500px',
};

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: 'greedy',
  scaleControl: true,
  streetViewControl: true,
};

interface IProps {
  center?: IPosition;
  marker?: IMarker;
}

const MapComponent: React.FC<IProps> = (props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    libraries,
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    language: 'pt-BR',
    region: 'br',
  });

  const { center, marker } = props;

  const [map, setMap] = useState<google.maps.Map | null>();

  const mapRef = useRef<any>(null);
  const [selected, setSelected] = useState<IMarker | undefined>(undefined);

  const onLoad = (event: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds();
    event.fitBounds(bounds);
    setMap(event);
  };

  const panTo = useCallback((latLng: google.maps.LatLngLiteral) => {
    const { lat, lng } = latLng;
    mapRef.current?.panTo({ lat, lng });
    mapRef.current?.setZoom(10);
  }, []);

  useEffect(() => {
    setMap(map);
    mapRef.current = map;
  }, [map]);

  if (loadError) return <div>Erro ao carregar o mapa.</div>;

  return isLoaded ? (
    <div>
      <Locate panTo={panTo} />
      <GoogleMap
        options={options}
        onLoad={onLoad}
        mapContainerStyle={mapStyles}
        onUnmount={() => setMap(null)}
        center={center}
      >
        <Marker
          position={{
            lat: marker?.lat!,
            lng: marker?.lng!,
          }}
          onClick={() => {
            setSelected(marker);
          }}
        />
        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => setSelected(undefined)}
          >
            <div>
              <h2>Seu Imóvel.</h2>
              Seu Imóvel está aqui.
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  ) : null;
};

export default MapComponent;
