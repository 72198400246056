import { Dispatch } from 'react';
import {
  ICreatePropertyInput,
  IFilterPropertiesInput,
} from 'src/interfaces/property';
import { propertyServices } from 'src/services/property';
import { forms } from 'src/utils';
import { store } from '..';
import {
  CREATE_PROPERTY,
  FETCH_PROPERTIES,
  FETCH_PROPERTY,
  FILTERED_PROPERTIES,
  PropertyActionTypes,
} from './types';

export const fetchProperties =
  () => (dispatch: Dispatch<PropertyActionTypes>) => {
    return propertyServices
      .fetchProperties()
      .then((res) => {
        dispatch({
          type: FETCH_PROPERTIES,
          payload: res,
        });
        return res;
      })
      .catch(() => {
        throw Error;
      });
  };

export const fetchProperty =
  (id: string) => (dispatch: Dispatch<PropertyActionTypes>) => {
    return propertyServices
      .fetchProperty(id)
      .then((res) => {
        dispatch({
          type: FETCH_PROPERTY,
          payload: res,
        });
        return res;
      })
      .catch(() => {
        throw Error;
      });
  };

export const fetchFilteredProperties =
  (input: IFilterPropertiesInput) =>
  (dispatch: Dispatch<PropertyActionTypes>) => {
    return propertyServices
      .fetchFilteredProperties(input)
      .then((res) => {
        dispatch({
          type: FILTERED_PROPERTIES,
          payload: res,
        });
        return res;
      })
      .catch(() => {
        throw Error;
      });
  };

export const createProperty =
  (input: ICreatePropertyInput) =>
  (dispatch: Dispatch<PropertyActionTypes>) => {
    input.images = store.getState().properties.images;
    input.scripture = store.getState().properties.scripture;
    input.registration = store.getState().properties.registration;
    input.subscription = store.getState().properties.subscription;
    input.car = store.getState().properties.car;
    input.ccir = store.getState().properties.ccir;
    input.itr = store.getState().properties.itr;
    input.area = store.getState().properties.area;
    input.values = store.getState().properties.values;

    return propertyServices
      .createProperty(input)
      .then((res) => {
        dispatch({
          type: CREATE_PROPERTY,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        forms.errors(err);
      });
  };
