import React, { Fragment } from 'react';
import { Button, Row, Tooltip } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

import { IProps } from './card';
import {
  Container,
  CardHeader,
  Title,
  Subtitle,
  CardBody,
} from './card.styles';

const CardComponent: React.FC<IProps> = (props) => {
  const { title, subtitle, children, button, tooltip } = props;

  return (
    <Container>
      {!!title && (
        <CardHeader>
          <Row align="middle" justify="space-between">
            <Title>{title}</Title>
            {button}
          </Row>
          {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
        </CardHeader>
      )}

      <CardBody>{children && children}</CardBody>
    </Container>
  );
};

export default CardComponent;
