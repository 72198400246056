import styled from 'styled-components';



export const Wrapper = styled.div`
  place-items: center;
  margin-bottom: 70px;
  
  @media (max-width: 768px) {
    margin-bottom: 0px;
    
  }
`;
