import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './reducers';
import { IReducerConfigState } from './config/types';
import { IReducerAuthState } from './auth/types';
import { IReducerPropertyState } from './property/types';
import { IReducerOwnerState } from './owner/types';
import { IReducerAppointmentState } from './appointments/types';
import { IReducerScheduleState } from './schedule/types';
import { IReducerBrokerState } from './broker/types';
import { IReducerClientState } from './clients/types';

const middleware = [thunk];

export const store = createStore(
  rootReducer,
  undefined,
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(...middleware)
    : require('redux-devtools-extension').composeWithDevTools(
        applyMiddleware(...middleware)
      )
);

export const persistor = persistStore(store);

export interface IReduxState {
  config: IReducerConfigState;
  auth: IReducerAuthState;
  properties: IReducerPropertyState;
  owner: IReducerOwnerState;
  broker: IReducerBrokerState;
  client: IReducerClientState;
  appointments: IReducerAppointmentState;
  schedules: IReducerScheduleState;
}
