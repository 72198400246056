import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IImage, IMarker } from 'src/interfaces/misc';
import { ReactComponent as BuildingSVG } from 'src/assets/images/building.svg';
import { ReactComponent as FarmSVG } from 'src/assets/images/farm.svg';
import { ReactComponent as HouseSVG } from 'src/assets/images/house.svg';
import { ReactComponent as IndustrySVG } from 'src/assets/images/industry.svg';

import styles from './styles.module.scss';

import Locate from '../locate';
import Search from '../search';

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

const libraries: Libraries = ['places'];

interface IPosition {
  lat: number;
  lng: number;
}

const mapStyles = {
  width: '100%',
  height: '90vh',
  marginTop: '50px',
};

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: 'greedy',
  scaleControl: true,
  streetViewControl: true,
};

type PropertyResume = {
  lat?: number;
  lng?: number;
  type: string;
  subtype?: string;
  code?: string;
  typeOfTransaction: 'sale' | 'rent';
  price: number;
  id: string;
  image?: IImage;
};

interface IProps {
  center?: IPosition;
  markers?: IMarker[];
  propertyResumes: PropertyResume[];
}

const SearchMap: React.FC<IProps> = (props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    libraries,
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    language: 'pt-BR',
    region: 'br',
  });

  const history = useHistory();

  const iconTypes = [
    { label: 'Apartamento', icon: '/publicIcons/house.svg' },
    { label: 'Apartamento Duplex', icon: '/publicIcons/house.svg' },
    { label: 'Apartamento Triplex', icon: '/publicIcons/house.svg' },
    { label: 'Cobertura Linear', icon: '/publicIcons/house.svg' },
    { label: 'Cobertura Duplex', icon: '/publicIcons/house.svg' },
    { label: 'Cobertura Triplex', icon: '/publicIcons/house.svg' },
    { label: 'Casa Linear', icon: '/publicIcons/house.svg' },
    { label: 'Casa Duplex', icon: '/publicIcons/house.svg' },
    { label: 'Casa Triplex', icon: '/publicIcons/house.svg' },
    { label: 'Terreno', icon: '/publicIcons/farm.svg' },
    { label: 'Sítio', icon: '/publicIcons/farm.svg' },
    { label: 'Fazenda', icon: '/publicIcons/farm.svg' },
    { label: 'Pousada', icon: '/publicIcons/house.svg' },
    { label: 'Garagem', icon: '/publicIcons/house.svg' },
    { label: 'Loja Linear', icon: '/publicIcons/building.svg' },
    { label: 'Loja Duplex', icon: '/publicIcons/building.svg' },
    { label: 'Loja Triplex', icon: '/publicIcons/building.svg' },
    { label: 'Prédio', icon: '/publicIcons/building.svg' },
    { label: 'Flat', icon: '/publicIcons/house.svg' },
    { label: 'Área Comercial', icon: '/publicIcons/industry.svg' },
    { label: 'Área Industrial', icon: '/publicIcons/industry.svg' },
    { label: 'Condomínio', icon: '/publicIcons/industry.svg' },
    { label: 'Industria', icon: '/publicIcons/industry.svg' },
    { label: 'Ilha', icon: '/publicIcons/farm.svg' },
    { label: 'Hotel', icon: '/publicIcons/house.svg' },
    { label: 'Loft', icon: '/publicIcons/house.svg' },
  ];

  const { center, markers, propertyResumes } = props;

  const [map, setMap] = useState<google.maps.Map | null>();

  const [currentPosition, setCurrentPosition] =
    useState<{ lat: number; lng: number }>();

  const mapRef = useRef<any>(null);
  const [selected, setSelected] = useState<PropertyResume | undefined>(
    undefined
  );

  const onLoad = (event: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds();
    event.fitBounds(bounds);
    setMap(event);
  };

  const panTo = useCallback((latLng: google.maps.LatLngLiteral) => {
    const { lat, lng } = latLng;
    mapRef.current?.panTo({ lat, lng });
    mapRef.current?.setZoom(15);
  }, []);

  function goToProperty(id: string) {
    console.log(id);
    history.push(`/painel/imovel/${id}`);
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentPosition({
        lat: position.coords.longitude,
        lng: position.coords.longitude,
      });
    });

    setMap(map);
    mapRef.current = map;
  }, [map]);

  if (loadError) return <div>Erro ao carregar o mapa.</div>;

  return isLoaded ? (
    <div style={{ position: 'relative' }}>
      <Search panTo={panTo} />
      <Locate panTo={panTo} isSearch />
      <GoogleMap
        options={options}
        onLoad={onLoad}
        zoom={10}
        mapContainerStyle={mapStyles}
        onUnmount={() => setMap(null)}
        center={center || currentPosition}
      >
        {propertyResumes && (
          <>
            {propertyResumes?.map((resume) => {
              return (
                <Marker
                  key={resume.lat}
                  icon={{
                    url: iconTypes.find(
                      (iconType) => iconType.label === resume.type
                    )?.icon!,
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                  position={{
                    lat: resume.lat!,
                    lng: resume.lng!,
                  }}
                  onClick={() => {
                    setSelected(resume);
                  }}
                />
              );
            })}
          </>
        )}

        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat!, lng: selected.lng! }}
            onCloseClick={() => setSelected(undefined)}
          >
            <div className={styles.resumeContainer}>
              <img src={selected.image?.path} alt="Preview image" />
              <h2>{selected?.type}</h2>
              <h3>{selected?.code}</h3>

              <div className={styles.textContainer}>
                <p>
                  Tipo de transação:{' '}
                  <span>
                    {selected?.typeOfTransaction === 'sale'
                      ? 'Venda'
                      : 'Aluguel'}
                  </span>
                </p>
                <p>
                  Valor:{' '}
                  <span>
                    {selected?.price?.toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </span>
                </p>
              </div>
              <button
                className={styles.propertyDetailsButton}
                onClick={() => goToProperty(selected.id)}
              >
                Ver mais detalhes
              </button>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  ) : null;
};

export default SearchMap;
