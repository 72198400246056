import { BrokerActionTypes, CLEAR_BROKER, FETCH_BROKER, IReducerBrokerState } from "./types";
import storage from 'redux-persist/es/storage';
import { persistReducer } from "redux-persist";


const initialState: IReducerBrokerState = {
  broker: undefined,
}

const persistConfig = {
  storage,
  key: 'broker',
  whitelist: ['broker'],
}

const reducer = (state = initialState, action: BrokerActionTypes) => {
  switch (action.type) {
    case FETCH_BROKER:
    return {
      ...state,
      broker: action.payload,
    }
    case CLEAR_BROKER:
      return {
        ...state,
        broker: undefined,
      }
    default:
      return state;
  }
}

export default persistReducer(persistConfig, reducer);