import { Dispatch } from "react"
import { brokerServices } from "src/services/broker"
import { BrokerActionTypes, FETCH_BROKER } from "./types"






export const fetchBroker = () => (dispatch: Dispatch<BrokerActionTypes>) => {
  return brokerServices.broker().then(res => {
    dispatch({
      type:  FETCH_BROKER,
      payload: res,
    })
    return res;
  }).catch(() => {
    throw Error
  })
}