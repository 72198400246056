import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IClient } from 'src/interfaces/client';
import { IReduxState } from 'src/store';
import { CLEAR_CLIENT } from 'src/store/clients/types';
import { fetchClient } from 'src/store/clients/actions';
import { Col, Descriptions, notification, Row, Skeleton } from 'antd';
import { Card } from 'src/components';
import { maritalStatus } from 'src/utils';

interface IParams {
  id: string;
}

type IProps = RouteComponentProps & {
  client?: IClient;
  fetchClient: (id: string) => Promise<IClient>;
};

const Client: FC<IProps> = (props) => {
  const { client } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { Item } = Descriptions;

  useEffect(() => {
    const id = (props.match.params as IParams).id;
    setLoading(true);
    props
      .fetchClient(id)
      .then((res) => {
        if (res) setLoading(false);
      })
      .catch((err) => {
        if (err) {
          notification.error({
            message:
              'Ocorreu um erro ao mostrar os dados deste cliente, por favor tente novamente mais tarde ou contate o suporte.',
          });
        }
      });

    return () => {
      dispatch({
        type: CLEAR_CLIENT,
      });
    };
  }, []);

  return (
    <Card>
      {loading ? (
        <Skeleton active={loading} paragraph={{ rows: 6 }} />
      ) : (
        <>
          <Col md={20} lg={20} xs={16} sm={8}>
            <h1> {client?.fullName} </h1>
          </Col>
          <br />
          <Descriptions
            title="Informações Básicas"
            size="middle"
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Item label="Estado Cívil">
              {
                maritalStatus.find(
                  (item) => item.value === client?.maritalStatus
                )?.name
              }
            </Item>
            <Item label={client?.document ? 'CPF' : 'CNPJ'}>
              {client?.document ? client.document : client?.cnpj}
            </Item>

            <Item label="E-Mail">{client?.email}</Item>
            <Item label="Telefone">{client?.phone}</Item>
          </Descriptions>
          <Descriptions
            title="Informações de Endereço."
            size="middle"
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Item label="Rua">
              {client?.address.street}, {client?.address.number},{' '}
              {client?.address.neighborhood} {client?.address.complement}
            </Item>

            <Item label="Cidade">
              {client?.address.city}, {client?.address.zipcode}
            </Item>

            <Item label="Estado">{client?.address.state}</Item>
          </Descriptions>
        </>
      )}
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  client: state.client.client,
});

export default connect(mapStateToProps, { fetchClient })(Client);
