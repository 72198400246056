import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  h1 {
    text-align: center;
    font-family: Orbitron, sans-serif;
    color: var(--app-terciary-color);
    font-size: 26px;
    padding-bottom: 10px;
    font-weight: 400;
  }
`;

export const Container = styled.div`
  padding: 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 3rem;
  padding: 0 35%;
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--app-terciary-color);
  }
  button {
    margin-top: 0.75rem;
  }
  justify-content: center;
  /* box-shadow: rgba(40, 40, 40, 0.2) 0px 0px 5px 1px; */
  /* background: #fff; */
`;
