import { Dispatch } from "react";
import { ICreateAppointmentInput } from "src/interfaces/appointment";
import { appointmentService } from "src/services/appointment";
import { forms } from "src/utils";
import { store } from "..";
import { AppointmentActionTypes, CREATE_APPOINTMENT, FETCH_APPOINTMENTS } from "./types";


export const fetchAppointments = () => (dispatch: Dispatch<AppointmentActionTypes>) => {

  return appointmentService.fetchAppointments().then((res) => {
    dispatch({
      type: FETCH_APPOINTMENTS,
      payload: res,
    })
    return res;
  }).catch(() => {
    throw Error;
  })
}
export const createAppointment = (input: ICreateAppointmentInput) => (dispatch: Dispatch<AppointmentActionTypes>) => {
  return appointmentService.createAppointment(input).then(res => {
    dispatch({
      type: CREATE_APPOINTMENT,
      payload: res,
    })
    return res;
  }).catch(err => {
    forms.errors(err);
  })
}

