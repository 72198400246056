import { Layout } from 'antd';
import { createBrowserHistory } from 'history';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { Network } from 'src/components';
import { IReduxState } from 'src/store';
import { Wrapper } from './app.styles';
import Drawer from './components/drawer/drawer.component';
import FooterComponent from './components/footer/footer.component';
import Sidebar from './components/sidebar/sidebar.component';
import SW from './components/sw/sw.component';
import Routes from './routes/routes.component';

export const history = createBrowserHistory();

const App: React.FC = () => {
  const state = useSelector((state: IReduxState) => state);

  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const { Header, Content, Footer } = Layout;

  const setToggle = () => {
    setCollapsed(!collapsed);
  };

  const onOpenDrawer = () => {
    setVisible(true);
  };

  const onCloseDrawer = () => {
    setVisible(false);
  };

  return (
    <Router history={history}>
      <Layout style={{ height: '100vh' }}>
        {/* <Header>
          <HeaderComponent
            setToggle={setToggle}
            collapsed={collapsed}
            setVisible={onOpenDrawer}
          />
        </Header> */}
        <Layout style={{ minHeight: 'auto', position: 'relative' }}>
          <Sidebar toggle={collapsed} />

          <Drawer visible={visible} onClose={onCloseDrawer} />
          <Content data-is-logged={Boolean(state.auth.user)}>
            {state.auth.user ? (
              <Wrapper>
                <Routes />
                <SW />

                <Footer>
                  <FooterComponent />
                </Footer>
              </Wrapper>
            ) : (
              <>
                <Routes />
                <SW />

                <Footer>
                  <FooterComponent />
                </Footer>
              </>
            )}

            <Network />
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default process.env.NODE_ENV === 'production'
  ? App
  : require('react-hot-loader').hot(module)(App);
