import { IClient } from 'src/interfaces/client';

export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_CLIENT = 'FETCH_CLIENT';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CLEAR_CLIENT = 'CLEAR_CLIENT';
export const SEND_PDFS = 'SEND_PDFS';

export interface IReducerClientState {
  client?: IClient;
  clients?: IClient[];
}

export interface IFetchClient {
  type: typeof FETCH_CLIENT;
  payload: IClient;
}

export interface IFetchClients {
  type: typeof FETCH_CLIENTS;
  payload: IClient[];
}

export interface ICreateClient {
  type: typeof CREATE_CLIENT;
  payload: IClient;
}

export interface IClearClient {
  type: typeof CLEAR_CLIENT;
}

export interface ISendPDFs {
  type: typeof SEND_PDFS;
  payload: any;
}

export type ClientActionTypes =
  | IFetchClient
  | IFetchClients
  | IClearClient
  | ISendPDFs
  | ICreateClient;
