import styled from 'styled-components';


export const OwnerWrapper = styled.div`
  margin: 0 auto;
  padding: 0 15px;
  border-radius: 5px;
  max-width: 1200px;
  width: 100%;
  h1 ,h3,h2 {
    
  }
  h4 {
    text-align: center;
    color: rgba(0, 0, 0, 1);
    padding: 5px 0;
  }

`;

export const OwnerContainer = styled.div`
  background: #fff;
  margin: 0 auto;
  padding: 15px;
  color: #000;
  border-radius: 5px;
  box-shadow: rgba(40, 40, 40, 0.2) 0px 0px 5px 1px;
  max-width: 1200px;
  width: 100%;
`;