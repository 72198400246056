import React, { useState } from 'react';

import {
  Container,
  MainInfo,
  CaracteristicsInfo,
  PriceInfo,
} from './property.card.styles';
import { IProps } from './property.card.types';
import { Carousel } from 'react-responsive-carousel';
import { FaBed, FaUniversity } from 'react-icons/fa';
import { states } from 'src/utils';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IProperty } from 'src/interfaces/property';
import { store } from 'src/store';
import {
  GET_PDF,
  MARKED_PROPERTIES,
  REMOVE_MARKED_PROPERTIES,
  REMOVE_PDF,
} from 'src/store/property/types';
import { usePDF } from '@react-pdf/renderer';
import PropertyPDF from '../propertyPDF';

const PropertyCard: React.FC<IProps> = (props) => {
  const { property, link } = props;
  const [instance, setInstance] = usePDF({
    document: <PropertyPDF key={property.id} property={property} />,
  });
  const [checked, setChecked] = useState(false);
  const { caracteristics } = property;

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      const reader = new FileReader();
      reader.readAsDataURL(instance.blob!);
      reader.onloadend = () => {
        const base64 = reader.result;
        const pdfObject = {
          path: base64,
          fileName: `${property.address.state}-${property.code}-${property.type}`,
        };
        store.dispatch({
          type: GET_PDF,
          payload: pdfObject,
        });
      };
      store.dispatch({
        type: MARKED_PROPERTIES,
        payload: property,
      });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(instance?.blob!);
      reader.onloadend = () => {
        const base64 = reader.result;
        store.dispatch({
          type: REMOVE_PDF,
          payload: base64,
        });
      };
      store.dispatch({
        type: REMOVE_MARKED_PROPERTIES,
        payload: property,
      });
    }
  };

  return (
    <Container>
      <div className="propertyCode">{property?.stringCode || 'RJ-001'}</div>
      {instance?.blob && (
        <Checkbox
          className="propertyCheck"
          checked={checked}
          onChange={onChange}
        />
      )}
      <Carousel
        width="100%"
        dynamicHeight={true}
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        className="carousel-bordered"
      >
        {property.images.map((image) => (
          <div key={image.path}>
            <img
              src={image.path}
              style={{
                width: '100%',
                height: '160px',
                objectFit: 'cover',
              }}
              alt="Imagens do Imóvel"
            />
          </div>
        ))}
      </Carousel>

      <Link to={link}>
        <MainInfo>
          <span>
            {' '}
            {property.type === 'Apartamento' ||
            'Apartamento Duplex' ||
            'Apartamento Triplex'
              ? property.type
              : property.type}
          </span>
          <p> {property.address.street} </p>
          <p className="city-state">
            {property.address.city},{' '}
            {states.find((state) => state.uf === property.address.state)?.name}
          </p>
        </MainInfo>
      </Link>
      <CaracteristicsInfo>
        {property.type !== 'Terreno' ? (
          <>
            {caracteristics?.rooms ? (
              <span>
                <FaBed /> {caracteristics?.rooms}{' '}
                {caracteristics?.rooms > 1 ? 'Quartos' : 'Quartos'}
              </span>
            ) : null}

            {property.area?.privateArea ? (
              <span>
                <FaUniversity />
                {property.area?.privateArea?.toLocaleString('pt-BR', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                })}{' '}
                m²
              </span>
            ) : null}
          </>
        ) : null}
      </CaracteristicsInfo>

      <PriceInfo>
        {property.values.salePrice ? (
          <>
            <p>Preço de Venda:</p>
            <span>
              {property.values.salePrice?.toLocaleString('pt-BR', {
                currency: 'BRL',
                style: 'currency',
              })}
            </span>
          </>
        ) : (
          <>
            <p>Aluguel: </p>
            <span>
              {property.values.rentPrice?.toLocaleString('pt-BR', {
                currency: 'BRL',
                style: 'currency',
              })}
            </span>
          </>
        )}
      </PriceInfo>
    </Container>
  );
};

export default PropertyCard;
